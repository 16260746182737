import {
  translationsEn as overviewTabEn,
  translationsJp as overviewTabJp,
} from "./overviewTabI18n";
import {
  translationsEn as chartsTabEn,
  translationsJp as chartsTabJp,
} from "./chartsI18n";
export const translationsEn = {
  // sidenav
  riskI18n: "RISK",
  RiskHeatMapI18n: "Risk Heat Map",
  countryRiskI18n: "Country Risk",
  compareCountriesI18n: "Compare Countries",
  // worldmap
  tier1I18n: "Tier 1",
  tier2I18n: "Tier 2",
  tier2WatchListI18n: "Tier 2 Watch List",
  tier3I18n: "Tier 3",
  noTierI18n: "No Tier",
  // tab names
  overviewTabNameI18n: "Overview",
  dashboardTabNameI18n: "Dashboard",
  riskTabNameI18n: "Risk",
  freedomTabNameI18n: "Freedom",
  corruptionTabNameI18n: "Corruption",
  SDGRecommendationTabNameI18n: "SDG Recommendations",
  NGOActionsTabNameI18n: "NGO Actions",
  // general
  scaleExplanationI18n: "Scale Explanation",
  categoryExplanationI18n: "Category Explanation",
  scaleRangeI18n: "THE SCALE RANGE IS  ",
  scoreI18n: "Score",
  rankI18n: "Rank",
  ...overviewTabEn,
  ...chartsTabEn,
  // risk tab
  riskTabTitleI18n: "RISK REPORT - ",
  riskFreeI18n: "RISK FREE",
  hightlyRiskyI18n: "HIGHLY RISKY",
  //freedom tab
  freedomTabTitleI18n: "FREEDOM REPORT - ",
  freedomReportOneLinerTitleI18n: "What is Freedom Report ?",
  freedomReportWidgetTitleI18n: "Freedom score for the year ",
  freedomReportOverviewTitleI18n: "Overview for the year ",
  freedomReportFreeTextI18n: "Free",
  freedomReportPartiallyFreeTextI18n: "Partially Free",
  freedomReportNotFreeTextI18n: "Not Free",
  //corruption tab
  corruptionTabTitleI18n: "CORRUPTION REPORT - ",
  corruptionReportOneLinerTitleI18n: "What is Corruption ?",
  corruptionScoreI18n: "Corruption score for the year ",
  corruptionScaleCleanI18n: "VERY CLEAN",
  corruptionScaleCorruptI18n: "HIGHLY CORRUPT",
  // sdg recommendation
  SDGRecommendationTabTitleI18n: "SDG RECOMMENDATION REPORT - ",
  SDGRecommendationOneLinerTitleI18n:
    "What is Sustainable Development Goals (SDG)?",
  SDGRecommendationGoalListTitleI18n: "The 17 SDG Goals",
  // NGO Actions tab
  NGOActionsTabTitleI18n: "NGO ACTIONS REPORT - ",
  NGOActionsReportOneLinerTitleI18n: "What is NGO Actions ?",
};
export const translationsJp = {
  //sidenav
  riskI18n: "リスク",
  RiskHeatMapI18n: "リスクヒートマップ",
  countryRiskI18n: "国リスク",
  compareCountriesI18n: "国の比較",
  //   worldmap
  tier1I18n: "ティアー1",
  tier2I18n: "ティアー2",
  tier2WatchListI18n: "ティアー2 ウォッチリスト",
  tier3I18n: "ティアー3",
  noTierI18n: "ティアなし",
  // tab names
  overviewTabNameI18n: "概要",
  dashboardTabNameI18n: "ダッシュボード",
  riskTabNameI18n: "リスク",
  freedomTabNameI18n: "フリーダム",
  corruptionTabNameI18n: "腐敗",
  SDGRecommendationTabNameI18n: "SDGの提言",
  NGOActionsTabNameI18n: "NGOアクション",
  // general
  scaleExplanationI18n: "スケール説明",
  categoryExplanationI18n: "カテゴリー 説明",
  scaleRangeI18n: "スケール範囲は ",
  scoreI18n: "スコア",
  rankI18n: "ランク",
  ...overviewTabJp,
  ...chartsTabJp,
  //risk tab
  riskTabTitleI18n: "リスクレポート - ",
  riskFreeI18n: "無リスク",
  hightlyRiskyI18n: "非常に危険",
  // freedom tab
  freedomTabTitleI18n: "自由報告 - ",
  freedomReportOneLinerTitleI18n: "フリーダムレポートとは？",
  freedomReportWidgetTitleI18n: "自由度スコア  ",
  freedomReportOverviewTitleI18n: "自由度概要  ",
  freedomReportFreeTextI18n: "自由",
  freedomReportPartiallyFreeTextI18n: "部分的に自由",
  freedomReportNotFreeTextI18n: "自由がなし",
  //corruption tab
  corruptionTabTitleI18n: "破損報告 - ",
  corruptionReportOneLinerTitleI18n: "破損とは？",
  corruptionScoreI18n: "破損スコア - ",
  corruptionScaleCleanI18n: "本音",
  corruptionScaleCorruptI18n: "腐敗",
  // sdg recommendation
  SDGRecommendationTabTitleI18n: "SDG提言レポート - ",
  SDGRecommendationOneLinerTitleI18n:
    "サステインナブル デベロップメント ゴール （SDG）とは ?",
  SDGRecommendationGoalListTitleI18n: "17-SDGゴール",
  // NGO actions tab
  NGOActionsTabTitleI18n: "NGOアクションレポート - ",
  NGOActionsReportOneLinerTitleI18n: "NGOアクションとは？",
};
