export const objectIsEmpty = (obj) => {
  return (
    obj && // 👈 null and undefined check
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};

export const capitalizeAndRemoveUnderscore = (str, capitalize = true) => {
  if (!str) return "";
  const processedString = str.replaceAll("_", " ");
  return capitalize
    ? processedString[0].toUpperCase() + processedString.slice(1)
    : processedString;
};

export const checkForLoadingState = (requestList, actionName) => {
  let isLoading = false;
  if (requestList && requestList.length) {
    let filteredResult = requestList.filter(
      (request) => request.requestName === actionName
    );
    isLoading = filteredResult.length ? filteredResult[0].inProgress : false;
  }
  return isLoading;
};

export const calculateChartMultilineName = (
  category,
  maxLength,
  maxLine = 2
) => {
  let newCategory = [];
  category.forEach((item) => {
    let newItem = [];

    while (item.length) {
      let temp = "";
      // if the current item length is lower then return the complete item
      if (item.length <= maxLength) {
        newItem.push(item);
        break;
      } else {
        temp = item.toString().slice(0, maxLength);
        temp = temp.toString().split(" ");
        temp = temp.slice(0, temp.length - 1);
        temp = temp.join(" ");
        newItem.push(temp);
      }

      // removing temp from item
      item = item.split("");
      item.splice(0, temp.length + 1);
      item = item.join("");
      // adding ellipses at the last array
      if (item.length && newItem.length === maxLine) {
        let temp = newItem[newItem.length - 1];
        newItem[newItem.length - 1] = temp.slice(0, temp.length - 3) + "...";
        break;
      }
    }
    newCategory.push([...newItem]);
  });
  return newCategory;
};

export const camel2title = (camelCase) =>
  camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();

export const numFormatter = (num, language = "en") => {
  if (language === "en") {
    if (num < 1000) {
      return num + " "; // if value < 1000, nothing to do
    } else if (num > 999 && num < 1000000) {
      let r = num % 1000 === 0 ? num / 1000 : (num / 1000).toFixed(1);
      return r + " K";
      // convert to K for number from > 1000 < 1 million
    } else if (num > 999999 && num < 1000000000) {
      let r = num % 1000000 === 0 ? num / 1000000 : (num / 1000000).toFixed(2);
      return r + " M"; // convert to M for number from > 1 million
    } else if (num > 999999999) {
      let r =
        num % 1000000000 === 0
          ? num / 1000000000
          : (num / 1000000000).toFixed(2);
      return r + " B"; // convert to M for number from > 1 million
    }
  } else {
    if (num < 1000) {
      return num + " "; // if value < 1000, nothing to do
    } else if (num > 999 && num < 10000) {
      let r = num % 1000 === 0 ? num / 1000 : (num / 1000).toFixed(2);
      return r + " 千";
    } else if (num > 9999 && num < 100000000) {
      let r = num % 10000 === 0 ? num / 10000 : (num / 10000).toFixed(2);
      return r + " 千";
    } else if (num > 99999999) {
      let r =
        num % 100000000 === 0 ? num / 100000000 : (num / 100000000).toFixed(2);
      return r + " 億"; // convert to M for number from > 1 million
    }
  }
};

export const calculateAverage = (numbers) => {
  if (numbers.length === 0) {
    return 0; // Handle edge case of an empty list
  }
  const sum = numbers.reduce((acc, val) => acc + val, 0);
  const average = sum / numbers.length;
  return average;
};
