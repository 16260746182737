export const translationsEn = {
  // widgets
  capitalCityI18n: "Capital",
  populationI18n: "Population",
  areaI18n: "Area",

  flagAndMapI18n: "Flag & Map",
  chooseCountryI18n: "Choose Country",
};
export const translationsJp = {
  // widgets
  capitalCityI18n: "首都",
  populationI18n: "人口",
  areaI18n: "エリア",
  flagAndMapI18n: "旗と地図",
  chooseCountryI18n: "別の国を選ぶ",
};
