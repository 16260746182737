const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  appName: "esg_report_generator",
  basename: "/",
  defaultPath: "",
  fontFamily: `'Noto Sans Japanese','Roboto', sans-serif`,
  fontFamily2: `'monospace'`,
  headerHeight: "50px",
  borderRadius: 4,
  defaultLanguage: "en",
  JWTAccessToken: process.env.REACT_APP_ENV
    ? process.env.REACT_APP_ENV + "EsgReportAccessToken"
    : "localEsgReportAccessToken",
  JWTRefreshToken: process.env.REACT_APP_ENV
    ? process.env.REACT_APP_ENV + "EsgReportRefreshToken"
    : "localEsgReportRefreshToken",
};

export default config;
