/* eslint-disable no-unused-vars */
import { forwardRef } from "react";
import PropTypes from "prop-types";
// mui
import { Box, Stack, Typography, useTheme } from "@mui/material";
// ui-component
import { ScaleLoader } from "react-spinners";
import NoData from "pages/Misc/NoData";
import InfoButton from "./infoButton";

const CustomCard = forwardRef(
  (
    {
      children,
      sx = {},
      bodySx = {},
      hideTitle = false,
      hideTitleInfo = false,
      titleText = "",
      titleSx = {},
      titleInfoText = "",
      loading = false,
      noData = false,
    },
    ref
  ) => {
    const theme = useTheme();

    return (
      <Box
        ref={ref}
        sx={{
          width: "100%",
          height: "100%",
          border: "1px solid" + theme.palette.grey[300],
          borderRadius: "4px",
          backgroundColor: theme.palette.background.paper,
          py: 2,
          ...sx,
        }}
      >
        {!hideTitle && (
          <Stack direction="row" sx={{ px: 2, width: "100%", height: "20px" }}>
            <Typography
              variant="h4"
              sx={{
                color: theme.palette.primary.dark,
                fontWeight: "600",
                pr: 1,
                fontFamily: "monospace",
                ...titleSx,
              }}
            >
              {titleText}
            </Typography>
            {!hideTitleInfo && (
              <InfoButton
                title={titleText}
                bodyComponent={<>{titleInfoText}</>}
              ></InfoButton>
              // <CustomInformationIcon infoText={titleInfoText} />
            )}
          </Stack>
        )}
        <Box
          sx={{
            width: "100%",
            height: hideTitle ? "100%" : "calc( 100% - 20px )",
            px: 2,
            ...bodySx,
          }}
        >
          {loading === true ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ScaleLoader color={theme.palette.secondary.dark} />
            </Box>
          ) : noData === true ? (
            <NoData />
          ) : (
            <Box sx={{ width: "100%", height: "100%" }}>{children}</Box>
          )}
        </Box>
      </Box>
    );
  }
);
CustomCard.displayName = "CustomCard";

CustomCard.propTypes = {
  sx: PropTypes.object,
  bodySx: PropTypes.object,
  titleSx: PropTypes.object,
  hideTitle: PropTypes.bool,
  hideTitleInfo: PropTypes.bool,
  titleText: PropTypes.string,
  titleInfoText: PropTypes.any,
  children: PropTypes.node,
  loading: PropTypes.bool,
  noData: PropTypes.bool,
};

export default CustomCard;
