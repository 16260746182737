/* eslint-disable no-unused-vars */
import { tabsClasses } from "@mui/material/Tabs";
export default function componentStyleOverrides(theme) {
  const bgColor = theme.colors?.grey50;
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.MuiIconButton-sizeXs": {
            fontSize: "15px",
            height: "20px",
            width: "20px",
          },
        },
      },
    },

    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
        rounded: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow:
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.colors?.textDark,
          padding: "24px",
        },
        title: {
          fontSize: "1.125rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          width: "100%",
          height: "48px",
          minHeight: "48x",
          background: "#ffffff",

          boxShadow: "inset 0 -1px 0 0 " + theme.colors.grey300,
        },
        indicator: {
          backgroundColor: theme.colors.primaryDark,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: "15px",
          fontWeight: 700,
          height: "48px",
          minHeight: "48px",
          color: theme.colors.grey600,

          "&:hover": {
            color: theme.colors.grey900,
            background: theme.colors.grey300,
          },
          "&.Mui-selected": {
            background: theme.colors.primaryLight,
            color: theme.colors.primaryDark,
            fontWeight: 700,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: "100% !important",
          background: bgColor,
          borderRadius: `${theme?.customization?.borderRadius}px`,
          "& .MuiOutlinedInput-notchedoutline": {
            borderColor: theme.colors?.grey400,
          },
          "&:hover $notchedoutline": {
            borderColor: theme.colors?.primaryLight,
          },
          "&.MuiInputBase-multiline": {
            padding: 1,
          },
        },
        input: {
          fontWeight: 700,
          background: bgColor,
          padding: "15.5px 14px",
          borderRadius: `${theme?.customization?.borderRadius}px`,
          "&.MuiInputBase-inputSizeSmall": {
            padding: "10px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedoutline: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-sizeXs": {
            height: "20px",
            ".MuiChip-label": {
              fontSize: "10px",
              padding: "8px",
            },
          },
          // "&.MuiChip-colorPrimaryLight": {
          //   background: "#e9fbf0",
          //   border: "1px solid #c6f6d9",
          //   color: theme.colors?.grey800,
          // },

          borderRadius: "4px",
          "&.MuiChip-deletable .MuiChip-deleteIcon": {
            color: "inherit",
          },
        },
      },
    },
  };
}
