const colorPalette = [
  "#812d04",
  "#ec7015",
  "#fbb441",
  "#88af8e",
  "#1d5e60",
  "#9e9e9e",
];

export const WorldMapLegendList = [
  {
    name: "Very High Risk",
    color: colorPalette[0],
  },
  {
    name: "High Risk",
    color: colorPalette[1],
  },
  {
    name: "Moderate Risk",
    color: colorPalette[2],
  },
  {
    name: "Low Risk",
    color: colorPalette[3],
  },
  {
    name: "Very Low Risk",
    color: colorPalette[4],
  },
  {
    name: "Not Available",
    color: colorPalette[5],
  },
];

export const WorldMapCalcTierColor = (tier) => {
  let color = "#f4eadb";
  switch (tier) {
    case WorldMapLegendList[0].name:
      color = WorldMapLegendList[0].color;
      break;
    case WorldMapLegendList[1].name:
      color = WorldMapLegendList[1].color;
      break;
    case WorldMapLegendList[2].name:
      color = WorldMapLegendList[2].color;
      break;
    case WorldMapLegendList[3].name:
      color = WorldMapLegendList[3].color;
      break;
    case WorldMapLegendList[4].name:
      color = WorldMapLegendList[4].color;
      break;
    case WorldMapLegendList[5].name:
      color = WorldMapLegendList[5].color;
      break;
    default:
      color = WorldMapLegendList[5].color;
  }
  return color;
};
