import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// material-ui
import ButtonBase from "@mui/material/ButtonBase";

// project imports
import config from "config";
import LogoWithText from "ui-component/LogoWithText";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ color }) => (
  <ButtonBase disableRipple component={Link} to={config.defaultPath}>
    <LogoWithText color={color} />
  </ButtonBase>
);

LogoSection.propTypes = {
  color: PropTypes.string,
};

export default LogoSection;
