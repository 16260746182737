/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ResponsivePie } from "@nivo/pie";
// mui
import useTheme from "@mui/material/styles/useTheme";
// ui-components
import CustomCard from "ui-component/card";
// utils
import { calculateChartMultilineName } from "utils/helper";

const SectorWithHRViolationChart = (props) => {
  const { loading } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const { sector_list: sectorList } = useSelector(
    (state) => state.countryRisk.countryStatistics
  );
  let [totalValue, setTotalValue] = useState(0);
  let chartTitle = t("humanRightsChartTitleI18n");
  let colorpalette = [
    // "#003f5c",
    // "#2f4b7c",
    // "#665191",
    // "#a05195",
    // "#d45087",
    // "#f95d6a",
    // "#ff7c43",
    // "#ffa600",
    // "#32CD32",
    // "#006837",
    theme.palette.chart[900],
    theme.palette.chart[800],
    theme.palette.chart[700],
    theme.palette.chart[600],
    theme.palette.chart[500],
    theme.palette.chart[400],
    theme.palette.chart[300],
    theme.palette.chart[200],
    theme.palette.chart[100],
    theme.palette.chart[50],
  ];
  useEffect(() => {
    if (sectorList) {
      const tempList = [];
      let tempTotal = 0;
      Object.entries(sectorList).map(([key, value], idx) => {
        tempTotal += value;
        tempList.push({
          id: key,
          label: key,
          value: value,
          color: colorpalette[idx % 11],
        });
      });
      setData(tempList);
      setTotalValue(tempTotal);
    }
  }, [sectorList]);

  return (
    <CustomCard
      titleText={chartTitle}
      titleInfoText={t("humanRightsChartInfoI18n")}
      loading={loading}
      noData={data.length === 0}
    >
      {data.length !== 0 && (
        <ResponsivePie
          data={data.slice(0, 10)}
          startAngle={90}
          endAngle={450}
          margin={{ top: 40, right: 125, bottom: 20, left: 125 }}
          innerRadius={0}
          padAngle={0}
          cornerRadius={0}
          activeOuterRadiusOffset={10}
          activeInnerRadiusOffset={10}
          borderWidth={1}
          // colors={{ scheme: "red_blue" }}
          colors={{ datum: "data.color" }}
          // enableArcLabels={false}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.5]],
          }}
          arcLabel={function (e) {
            return ((e.value / totalValue) * 100).toFixed(0) + "%";
          }}
          arcLinkLabel={(d) => {
            const label = calculateChartMultilineName([d.data.label], 20)[0];
            return (
              <>
                {label.length === 1 ? (
                  <tspan x="0" dy="0">
                    {label[0]}
                  </tspan>
                ) : (
                  label.map((item, idx) => {
                    return (
                      <tspan key={idx} x="0" dy={idx === 0 ? "-1em" : "1.2em"}>
                        {item}
                      </tspan>
                    );
                  })
                )}
              </>
            );
          }}
          arcLinkLabelsStraightLength={10}
          arcLinkLabelsDiagonalLength={20}
          arcLinkLabelsSkipAngle={5}
          arcLinkLabelsTextColor={theme.palette.grey[600]}
          arcLinkLabelsThickness={1}
          arcLinkLabelsColor={theme.palette.grey[400]}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={theme.palette.grey[50]}
          layers={["arcs", "arcLabels", "arcLinkLabels"]}
        />
      )}
    </CustomCard>
  );
};
export default SectorWithHRViolationChart;
SectorWithHRViolationChart.propTypes = {
  loading: PropTypes.bool,
};
