export const BASE_COUNTRY_ACTION_NAME = "@country_risk";
export const GET_WORLD_MAP_JSON_DATA =
  BASE_COUNTRY_ACTION_NAME + "/GET_WORLD_MAP_JSON_DATA";
export const SET_WORLD_MAP_JSON_DATA =
  BASE_COUNTRY_ACTION_NAME + "/SET_WORLD_MAP_JSON_DATA";
export const CLEAR_WORLD_MAP_JSON_DATA =
  BASE_COUNTRY_ACTION_NAME + "/CLEAR_WORLD_MAP_JSON_DATA";
// export const SET_INDICATOR_DATA =
//   BASE_COUNTRY_ACTION_NAME + "/SET_INDICATOR_DATA";
export const GET_COUNTRY_STATISTICS =
  BASE_COUNTRY_ACTION_NAME + "/GET_COUNTRY_STATISTICS";
export const SET_COUNTRY_STATISTICS =
  BASE_COUNTRY_ACTION_NAME + "/SET_COUNTRY_STATISTICS";
export const CLEAR_COUNTRY_STATISTICS =
  BASE_COUNTRY_ACTION_NAME + "/CLEAR_COUNTRY_STATISTICS";
export const GET_COUNTRY_LIST = BASE_COUNTRY_ACTION_NAME + "/GET_COUNTRY_LIST";
export const SET_COUNTRY_LIST = BASE_COUNTRY_ACTION_NAME + "/SET_COUNTRY_LIST";
export const SET_COUNTRY_ISO_A3 =
  BASE_COUNTRY_ACTION_NAME + "/SET_COUNTRY_ISO_A3";
