import axios from "axios";
import config from "config";
import { getBrowserStorageAccessToken } from "utils/authentication";
import { memoizedRefreshToken } from "./refreshToken";
import { objectIsEmpty } from "utils/helper";

export const AxiosPrivateInterceptorSetup = (navigate) => {
  let baseURL = process.env.REACT_APP_REST_API_URL || "http://localhost:8000/";
  axios.defaults.baseURL = baseURL;
  // REQUEST LOGIC
  // adding the access token in the request header
  axios.interceptors.request.use(
    async (request) => {
      const accessToken = getBrowserStorageAccessToken();
      if (accessToken) {
        request.headers = {
          ...request.headers,
          authorization: `Bearer ${accessToken}`,
        };
      }
      return request;
    },
    (error) => Promise.reject(error)
  );
  // RESPONSE LOGIC
  // if we get a 401 response ( unauthorized )
  // 1 ) try refreshing the access token using refresh token
  //    - if refresh token is vaid then we will receive the access token
  //    - else redirect to login page
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const axiosConfig = error?.config;
      if (error?.response?.status === 401 && !axiosConfig?.sent) {
        axiosConfig.sent = true;
        const token = await memoizedRefreshToken(navigate);
        if (!objectIsEmpty(token)) {
          // let baseVariable = process.env.REACT_APP_ENV
          //   ? process.env.REACT_APP_ENV
          //   : "local";
          localStorage.setItem(config.JWTAccessToken, token["access_token"]);
          localStorage.setItem(config.JWTRefreshToken, token["refresh_token"]);
          axiosConfig.headers = {
            ...axiosConfig.headers,
            authorization: `Bearer ${token["access_token"]}`,
          };
          return axios(axiosConfig);
        }
      }

      return Promise.reject(error);
    }
  );
};

export const axiosJWT = axios;
