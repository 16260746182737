export const translationsEn = {
  // sidenav

  companyRiskI18n: "Company Risk",
  generatePdfI18n: "Generate PDF",
};
export const translationsJp = {
  //sidenav
  companyRiskI18n: "会社リスク",
  generatePdfI18n: "PDFを作る",
};
