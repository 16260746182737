/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { calculateAverage, objectIsEmpty } from "utils/helper";
import CustomCard from "ui-component/card";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { hrCategoryNameList, sectorNameList } from "utils/constants";

// eslint-disable-next-line react/prop-types
const AreaLayer = ({ innerWidth, innerHeight }) => {
  const heightMultiplier = innerHeight / 5;
  const widthMultiplier = innerWidth / 5;
  return (
    <>
      {/* <path
        d={`M${widthMultiplier * 4} ${heightMultiplier * 4}H${innerWidth}V${
          heightMultiplier * 5
        }H${widthMultiplier * 4}V${heightMultiplier * 4}Z`}
        fill="url(#paint0_linear_854_37)"
      /> */}
      <path
        d={`M${widthMultiplier * 3} ${heightMultiplier * 3}H${innerWidth}V${
          heightMultiplier * 4
        }H${widthMultiplier * 3}V${heightMultiplier * 3}Z`}
        fill="url(#paint1_linear_854_37)"
      />
      <path
        d={`M${widthMultiplier * 2} ${heightMultiplier * 2}H${innerWidth}V${
          heightMultiplier * 3
        }H${widthMultiplier * 2}V${heightMultiplier * 2}Z`}
        fill="url(#paint1_linear_854_37)"
      />
      <path
        d={`M${widthMultiplier * 1} ${heightMultiplier * 1}H${innerWidth}V${
          heightMultiplier * 2
        }H${widthMultiplier * 1}V${heightMultiplier * 1}Z`}
        fill="url(#paint1_linear_854_37)"
      />
      <path
        d={`M${widthMultiplier * 0} ${heightMultiplier * 0}H${innerWidth}V${
          heightMultiplier * 1
        }H${widthMultiplier * 0}V${heightMultiplier * 0}Z`}
        fill="url(#paint1_linear_854_37)"
      />
      <defs>
        <linearGradient
          id="paint1_linear_854_37"
          x1={innerWidth}
          y1={innerHeight * -0.00000012}
          x2={innerWidth * 0.6145}
          y2={innerHeight * 1.601}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0000" />
          <stop offset="1" stopColor="#FFE601" stopOpacity="0.24" />
        </linearGradient>
      </defs>
    </>
  );
};
const SectorWiseChart = (props) => {
  const { countryAlpha3Code } = props;
  const theme = useTheme();
  const title = "Risk Assessment by Industry in ";
  const { selectedCountries: storeSelectedCountryList } = useSelector(
    (state) => state.compareCountries.search
  );
  const [data, setData] = useState([]);
  const [xScaleMax, setXScaleMax] = useState(0);
  const { supplyChainWithIssueCategory } = useSelector(
    (state) => state.compareCountries
  );
  useEffect(() => {
    if (!objectIsEmpty(supplyChainWithIssueCategory) && countryAlpha3Code) {
      //   let tempXScaleMax = 0;

      let campaignQuantity = [];
      const inputData = supplyChainWithIssueCategory[countryAlpha3Code];
      let tempData = Object.entries(inputData).map(([title, categories]) => ({
        title: sectorNameList[title],
        children: Object.entries(categories).map(([id, categoryData]) => {
          //   tempXScaleMax += categoryData.campaign_quantity;
          campaignQuantity.push(categoryData.campaign_quantity);
          return {
            id: hrCategoryNameList[id]["en"],
            data: [
              { x: categoryData.campaign_quantity, y: categoryData.risk_score },
            ],
          };
        }),
      }));
      setData(tempData);
      //   setXScaleMax(tempXScaleMax);
      setXScaleMax(
        Math.max(...campaignQuantity) + calculateAverage(campaignQuantity)
      );
    }
  }, [supplyChainWithIssueCategory, countryAlpha3Code]);

  return (
    <Box sx={{ width: "100%", height: "auto" }}>
      <Typography
        variant="h4"
        sx={{
          color: theme.palette.primary.dark,
          fontWeight: "600",
          fontFamily: "monospace",
          mb: 1,
        }}
      >
        {title +
          storeSelectedCountryList.filter(
            (item) => item.alpha3_code === countryAlpha3Code
          )[0]?.country}
      </Typography>
      <Grid container sx={{ width: "100%", height: "auto" }} spacing={2}>
        {data.map((item, idx) => {
          return (
            <Grid item xs={12} md={6} lg={4} key={idx} sx={{ height: "250px" }}>
              <CustomCard
                loading={false}
                titleText={item.title}
                titleInfoText={item.title}
                titleSx={{ fontSize: "0.78rem" }}
              >
                <ResponsiveScatterPlot
                  data={item.children}
                  margin={{ top: 20, right: 10, bottom: 50, left: 50 }}
                  xScale={{ type: "linear", min: 0, max: xScaleMax }}
                  xFormat=">-.2f"
                  yScale={{ type: "linear", min: 0, max: 5 }}
                  yFormat=">-.2f"
                  colors={{ scheme: "red_blue" }}
                  axisTop={null}
                  axisRight={null}
                  useMesh={false}
                  axisBottom={{
                    orient: "bottom",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Total Records",
                    legendPosition: "middle",
                    legendOffset: 40,
                  }}
                  axisLeft={{
                    orient: "left",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Risk Severity Score",
                    legendPosition: "middle",
                    legendOffset: -40,
                  }}
                  layers={[
                    "grid",
                    AreaLayer,
                    "axes",
                    "nodes",
                    "markers",
                    "mesh",
                    // "legends",
                    "annotations",
                  ]}
                  tooltip={({ node }) => {
                    return (
                      <Box
                        sx={{
                          borderRadius: 1,
                          color: theme.palette.grey[600],
                          background: theme.palette.grey[50],
                          maxWidth: "150px",
                          border: "1px solid " + theme.palette.grey[200],
                        }}
                      >
                        <Box sx={{ p: 1, background: theme.palette.grey[200] }}>
                          <Typography
                            sx={{
                              // color: node.color,
                              fontSize: "0.75rem",
                            }}
                          >
                            {`${node.serieId}`}
                          </Typography>
                        </Box>
                        <Stack
                          spacing={1}
                          direction="column"
                          alignItems="flex-start"
                          justifyContent="flex-start"
                          sx={{ px: 2, py: 1 }}
                        >
                          <Typography
                            variant="h5"
                            sx={{ fontFamily: "monospace" }}
                          >
                            {`Risk Score: `} {node.data.y}
                          </Typography>

                          <Typography
                            variant="h5"
                            sx={{ color: "inherit", fontSize: "0.8rem" }}
                          >
                            {`Record count: `}
                            <strong>{node.data.x}</strong>
                          </Typography>

                          {/* </Stack> */}
                        </Stack>
                      </Box>
                    );
                  }}
                />
              </CustomCard>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default SectorWiseChart;

SectorWiseChart.propTypes = {
  countryAlpha3Code: PropTypes.string.isRequired,
};
