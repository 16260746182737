import * as actionTypes from "./action";
const initialState = {
  requests: {
    "@company_risk": [],
    "@country_risk": [],
    "@compare_countries": [],
    "@user": [],
  },
  notification: { message: null, severity: null },
  information: { title: null, body: null, open: false },
};

export const ServiceAndRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_STARTED: {
      const moduleName = action?.request?.moduleName;
      const existingRequestsInModule = state.requests[moduleName];
      const existingRequests = existingRequestsInModule.find(
        (request) => request.requestName === action.request.requestName
      );

      if (existingRequests) {
        return {
          ...state,
          requests: {
            ...state.requests,
            [moduleName]: existingRequestsInModule.map((request) =>
              request.requestName === action.request.requestName
                ? { ...request, inProgress: true, error: null }
                : request
            ),
          },
        };
      }

      return {
        ...state,
        requests: {
          ...state.requests,
          [moduleName]: [...existingRequestsInModule, action.request],
        },
      };
    }

    case actionTypes.REQUEST_FINISHED: {
      const moduleName = action?.request?.moduleName;

      const existingRequestsInModule = state.requests[moduleName];
      return {
        ...state,
        requests: {
          ...state.requests,
          [moduleName]: existingRequestsInModule.filter(
            (request) => request.requestName !== action.request.requestName
          ),
        },
      };
    }

    case actionTypes.REQUEST_FAILED: {
      const moduleName = action.request.moduleName;
      const existingRequestsInModule = state.requests[moduleName];
      return {
        ...state,
        requests: {
          ...state.requests,
          [moduleName]: existingRequestsInModule.map((request) =>
            request.requestName === action.request.requestName
              ? {
                  ...request,
                  error: action.request.error,
                  inProgress: false,
                }
              : request
          ),
        },
        notification: {
          message: action.request.error,
          severity: action.request.severity,
        },
      };
    }

    case actionTypes.CLEAR_FAILED_REQUESTS: {
      return {
        ...initialState,
      };
    }

    case actionTypes.SET_NOTIFICATION: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionTypes.SET_INFORMATION: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
