import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";

import {
  BASE_COUNTRY_ACTION_NAME,
  SET_COUNTRY_STATISTICS,
} from "redux/countryRisk/action";
import { namedRequestsInProgress } from "redux/serviceAndRequest/selector";
import WidgetCard from "ui-component/cards/WiddgetCard";
import { FreedomChart } from "./components";

//assets
import freedomScale from "assets/images/risk/freedomScale.svg";
import { useTranslation } from "react-i18next";
import { TitleTypography } from "ui-component/titleTypography";

const FreedomTab = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  // const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  let overallScoreText = t("freedomChartOverallScoreI18n");
  let politicalRIghtText = t("freedomChartPoliticalRightsI18n");
  let civilLibertiesText = t("freedomChartCivilLibertiesI18n");
  let freeText = t("freedomReportFreeTextI18n");
  let partiallyFreeText = t("freedomReportPartiallyFreeTextI18n");
  let notFreeText = t("freedomReportNotFreeTextI18n");
  const [latestReport, setLatestReport] = useState({});
  const { name: countryName, freedom } = useSelector(
    (state) => state.countryRisk.countryStatistics
  );
  const { language, sideDrawerOpened } = useSelector(
    (state) => state.customization
  );
  const loading = useSelector((state) =>
    namedRequestsInProgress(state, {
      moduleName: BASE_COUNTRY_ACTION_NAME,
      requestName: SET_COUNTRY_STATISTICS,
    })
  );
  const explaination = {
    en: [
      "Freedom in the World is an annual global report on political rights and civil liberties, composed of numerical ratings and descriptive texts for each country and a select group of territories.Freedom in the World assesses the real-world rights and freedoms enjoyed by individuals, rather than governments or government performance per se.",
      "The report’s methodology is derived in large measure from the Universal Declaration of Human Rights, adopted by the UN General Assembly in 1948. Freedom in the World is based on the premise that these standards apply to all countries and territories, irrespective of geographical location, ethnic or religious composition, or level of economic development. Freedom in the World operates from the assumption that freedom for all people is best achieved in liberal democratic societies.",
    ],
    ja: [
      "Freedom in the World は、政治的権利と市民的自由に関する年次グローバル レポートであり、各国と選択された地域グループの数値評価と説明テキストで構成されています。 政府または政府のパフォーマンス自体よりも。",
      "報告書の方法論は、1948 年に国連総会で採択された世界人権宣言に大きく由来しています。世界の自由は、地理的位置、民族に関係なく、これらの基準がすべての国と地域に適用されるという前提に基づいています。 または宗教構成、または経済発展のレベル。 世界の自由は、すべての人々の自由が自由民主主義社会で最もよく達成されるという仮定に基づいて運営されています。",
    ],
  };
  const categoryExplaination = [
    {
      name: t("freedomChartPoliticalRightsI18n"),
      value: {
        en: "Political rights include natural justice (procedural fairness) in law, such as the rights of the accused, including the right to a fair trial; due process; the right to seek redress or a legal remedy; and rights of participation in civil society and politics such as freedom of association, the right to assemble, the right to petition, the right of self-defense, and the right to vote.",
        ja: "政治的権利には、公正な裁判を受ける権利を含む被告人の権利など、法律における自然正義 (手続き上の公平性) が含まれます。 適法手続; 救済または法的救済を求める権利。 結社の自由、集会の権利、請願権、自衛権、投票権などの市民社会と政治への参加の権利。",
      },
    },
    {
      name: t("freedomChartCivilLibertiesI18n"),
      value: {
        en: "Civil rights include the ensuring of peoples' physical and mental integrity, life, and safety; protection from discrimination on grounds such as sex, race, sexual orientation, national origin, color, age, political affiliation, ethnicity, social class, religion, and disability; and individual rights such as privacy and the freedom of thought, speech, religion, press, assembly, and movement.",
        ja: "公民権には、人々の身体的および精神的完全性、生命、および安全の確保が含まれます。 性別、人種、性的指向、出身国、肌の色、年齢、所属政党、民族性、社会階級、宗教、障害などに基づく差別からの保護。 プライバシー、思想、言論、宗教、報道、集会、運動の自由などの個人の権利。",
      },
    },
  ];
  useEffect(() => {
    if (freedom && freedom.length) {
      setLatestReport({ ...freedom[freedom.length - 1] });
    }
  }, [freedom]);
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        py: 2,
        px: {
          xs: 2,
          sm: 5,
          md: sideDrawerOpened ? 5 : 10,
          lg: sideDrawerOpened ? 10 : 20,
          xl: sideDrawerOpened ? 40 : 60,
        },
      }}
    >
      <Typography variant="h2" sx={{ color: theme.palette.primary[800] }}>
        {countryName
          ? t("freedomTabTitleI18n") + countryName?.toUpperCase()
          : ""}
      </Typography>

      <Grid container sx={{ px: 4, height: "100%", width: "100%" }}>
        {/* One liner */}
        <Grid item xs={12} sx={{ mb: 2 }}>
          {explaination[language].map((item, index) => {
            return (
              <Typography
                key={index}
                variant="h5"
                sx={{
                  color: theme.palette.text.main,
                  mt: 1,
                  fontWeight: "500",
                  lineHeight: "1.5rem",
                }}
              >
                {item}
              </Typography>
            );
          })}
        </Grid>
        {/*  widgets */}
        <Grid item xs={12} sx={{ my: 2 }}>
          <TitleTypography
            title={t("freedomReportWidgetTitleI18n") + latestReport.year}
          />
          <Box sx={{ mt: 1.5, height: "100%", width: "100%" }}>
            <Grid container sx={{ width: "100%", height: "100%" }}>
              <Grid
                item
                xs={12}
                sm={4}
                lg={4}
                sx={{
                  pr: { lg: 1, md: 1, sm: 1, xs: 0 },
                  pb: { xs: 1, md: 0 },
                }}
              >
                <WidgetCard isLoading={loading} cardTheme={"primary"}>
                  <Grid
                    container
                    rowSpacing={2}
                    direction="row"
                    alignItems="start"
                    justifyContent="start"
                  >
                    <Grid item xs={12}>
                      <Typography
                        // fontSize="3em"
                        fontWeight={300}
                        variant={"h1"}
                        component={"span"}
                        sx={{
                          color: theme.palette.grey[50],
                        }}
                      >
                        {latestReport?.score}
                        <Typography
                          fontWeight={500}
                          variant={"h2"}
                          component={"span"}
                          sx={{
                            color: theme.palette.grey[50],
                          }}
                        >
                          {" / 100"}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems={"center"}
                      justifyContent={"start"}
                    >
                      <Typography
                        variant={"h3"}
                        component={"span"}
                        mr={2}
                        sx={{ color: theme.palette.grey[50] }}
                      >
                        {overallScoreText} -{" "}
                        {latestReport?.score < 35
                          ? notFreeText
                          : latestReport?.score < 70
                          ? partiallyFreeText
                          : freeText}
                      </Typography>
                    </Grid>
                  </Grid>
                </WidgetCard>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                sx={{
                  pr: { lg: 1, md: 1, sm: 1, xs: 0 },
                  pl: { lg: 1, md: 1, sm: 1, xs: 0 },
                  pb: { xs: 1, md: 0 },
                }}
              >
                <WidgetCard isLoading={loading} cardTheme={"secondary"}>
                  <Grid
                    container
                    rowSpacing={2}
                    direction="row"
                    alignItems="start"
                    justifyContent="start"
                  >
                    <Grid item xs={12}>
                      <Typography
                        // fontSize="3em"
                        fontWeight={300}
                        variant={"h1"}
                        component={"span"}
                        sx={{
                          color: theme.palette.grey[50],
                        }}
                      >
                        {latestReport?.political_rights}
                        <Typography
                          fontWeight={500}
                          variant={"h2"}
                          component={"span"}
                          sx={{
                            color: theme.palette.grey[50],
                          }}
                        >
                          {" / 40"}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems={"center"}
                      justifyContent={"start"}
                    >
                      <Typography
                        variant={"h3"}
                        component={"span"}
                        mr={2}
                        sx={{ color: theme.palette.grey[50] }}
                      >
                        {politicalRIghtText}
                      </Typography>
                    </Grid>
                  </Grid>
                </WidgetCard>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                sx={{
                  pl: { lg: 1, md: 1, sm: 1, xs: 0 },
                  pb: { xs: 1, md: 0 },
                }}
              >
                <WidgetCard isLoading={loading} cardTheme={"secondary"}>
                  <Grid
                    container
                    rowSpacing={2}
                    direction="row"
                    alignItems="start"
                    justifyContent="start"
                  >
                    <Grid item xs={12}>
                      <Typography
                        // fontSize="3em"
                        fontWeight={300}
                        variant={"h1"}
                        component={"span"}
                        sx={{
                          color: theme.palette.grey[50],
                        }}
                      >
                        {latestReport.civil_liberties}
                        <Typography
                          fontWeight={500}
                          variant={"h2"}
                          component={"span"}
                          sx={{
                            color: theme.palette.grey[50],
                          }}
                        >
                          {" / 60"}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems={"center"}
                      justifyContent={"start"}
                    >
                      <Typography
                        variant={"h3"}
                        component={"span"}
                        mr={2}
                        sx={{ color: theme.palette.grey[50] }}
                      >
                        {civilLibertiesText}
                      </Typography>
                    </Grid>
                  </Grid>
                </WidgetCard>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {/* overview */}
        <Grid item xs={12} sx={{ my: 2 }}>
          <TitleTypography
            title={t("freedomReportOverviewTitleI18n") + latestReport.year}
          />
          <Typography
            variant="h5"
            sx={{
              color: theme.palette.text.main,
              fontWeight: "500",
              lineHeight: "1.5rem",
              mt: 1,
            }}
          >
            {latestReport?.overview}
          </Typography>
        </Grid>

        {/* chart */}
        <Grid item xs={12} sx={{ my: 2, height: "500px", width: "100%" }}>
          <FreedomChart />
        </Grid>

        {/* scale explaination */}
        <Grid item xs={12} sx={{ my: 2 }}>
          <TitleTypography title={t("scaleExplanationI18n")} />
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              height: "250px",
              width: "100%",
              mt: 1,
            }}
          >
            <Grid
              item
              xs={6}
              sx={{ width: { xs: "50%", md: "70%", lg: "100%" }, pr: 1 }}
            >
              <img
                width="100%"
                height="auto"
                src={freedomScale}
                alt=" 404 not found"
              />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ height: "100%", width: "100%", pl: 1 }}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="h3"
                fontWeight={300}
                sx={{ textAlign: "center" }}
              >
                {overallScoreText} = {politicalRIghtText} + {civilLibertiesText}
              </Typography>
              <Divider
                sx={{
                  my: 2,
                  borderBottomWidth: 2,
                  width: "100%",
                  borderColor: theme.palette.grey[500],
                }}
              />
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h3" fontWeight={300}>
                  {t("scaleRangeI18n")} {"0 "}
                  <span
                    style={{
                      fontWeight: 500,
                      fontStyle: "italic",
                    }}
                  >{`(${notFreeText})`}</span>
                  {" - 100 "}
                  <span
                    style={{ fontWeight: 500, fontStyle: "italic" }}
                  >{`(${freeText})`}</span>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* category explaination */}
        <Grid item xs={12} sx={{ my: 2 }}>
          <TitleTypography title={t("categoryExplanationI18n")} />
          {categoryExplaination.map((category, index) => {
            return (
              <Box key={index} sx={{ px: 5, mt: 1.5 }}>
                <Typography
                  sx={{ color: theme.palette.primary[800], fontWeight: 700 }}
                  variant="h5"
                >
                  {category.name}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    color: theme.palette.text.main,
                    fontWeight: "500",
                    lineHeight: "1.5",
                  }}
                >
                  {category.value[language]}
                </Typography>
              </Box>
            );
          })}
        </Grid>
      </Grid>
      {/* </MainCard> */}
    </Box>
  );
};

export default FreedomTab;
