import { axiosJWT } from "common/axiosPrivate";

// const endUrl = "/";

export const getCountriesComparisionData = async ({
  searchedCountryList,
  startDate,
  endDate,
  category,
  sector,
}) => {
  try {
    let response = await axiosJWT(
      "esg/compare-countries/?country_codes=" +
        searchedCountryList +
        "&start_date=" +
        startDate +
        "&end_date=" +
        endDate +
        "&category=" +
        category +
        "&sector=" +
        sector
    );
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};

export const getFilteredNGORecords = async (
  country,
  indicatorClass,
  indicatorKey,
  startDate,
  endDate
) => {
  try {
    let response = await axiosJWT(
      `esg/compare-country-filtered-ngo-records/?country=${country}&indicator_class=${indicatorClass}&indicator_name=${indicatorKey}&start_date=${startDate}&end_date=${endDate}`
    );
    return response.data;
  } catch (e) {
    throw " Error while fetching data";
  }
};
