import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";

// material-ui
import useTheme from "@mui/material/styles/useTheme";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";

// assets
import { IconLogout, IconSettings } from "@tabler/icons";
import {
  getBrowserStorageAccessToken,
  getBrowserStorageRefreshToken,
  logout,
} from "utils/authentication";
import { useTranslation } from "react-i18next";
import { objectIsEmpty } from "utils/helper";
import { logoutRefreshToken } from "redux/authentication/api";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  let navigate = useNavigate();
  const customization = useSelector((state) => state.customization);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [jwtObj, setJwtObj] = useState({});
  // const [accessToken, setAccessToken] = useState("");
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  //i18n
  const { t } = useTranslation();

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    const accessToken = getBrowserStorageAccessToken();
    if (accessToken) {
      setJwtObj(jwt_decode(accessToken));
    }
  }, []);

  const handleLogout = () => {
    logoutRefreshToken(getBrowserStorageRefreshToken());
    logout();
    navigate("/login");
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <Box sx={{ px: 2 }}>
      {objectIsEmpty(jwtObj) ? (
        <ButtonBase sx={{ borderRadius: "12px" }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: theme.palette.primary.light,
              color: theme.palette.primary.dark,
              '&[aria-controls="menu-list-grow"],&:hover': {
                background: theme.palette.primary.dark,
                color: theme.palette.primary.light,
              },
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleLogout}
            color="inherit"
          >
            <LoginOutlinedIcon />
          </Avatar>
        </ButtonBase>
      ) : jwtObj?.first_name && jwtObj?.last_name ? (
        <Avatar
          sx={{
            ...theme.typography.mediumAvatar,
            margin: "8px 0 8px 8px !important",
            cursor: "pointer",
            backgroundColor: theme.palette.warning.dark,
          }}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Typography
            variant="h5"
            sx={{ color: theme.palette.grey[50], fontWeight: 700 }}
          >
            {jwtObj?.first_name.split(" ")[0][0] +
              jwtObj?.last_name.split(" ")[0][0]}
          </Typography>
        </Avatar>
      ) : (
        <Avatar
          src="/broken-image.jpg"
          sx={{
            ...theme.typography.mediumAvatar,
            margin: "8px 0 8px 8px !important",
            cursor: "pointer",
            backgroundColor: theme.palette.warning.dark,
            color: theme.palette.grey[50],
          }}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        />
      )}
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 7],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ px: 2, pt: 2 }}>
                    <Stack sx={{ mb: 2 }}>
                      <Stack
                        direction="column"
                        spacing={0.5}
                        alignItems="center"
                        justifyContent="center"
                      >
                        {jwtObj?.first_name && jwtObj?.last_name ? (
                          <Avatar
                            // src="/broken-image.jpg"
                            sx={{
                              width: 70,
                              height: 70,
                              mb: 2,
                              backgroundColor: theme.palette.warning.dark,
                            }}
                            color="inherit"
                          >
                            <Typography
                              variant="h2"
                              sx={{ color: theme.palette.grey[50] }}
                            >
                              {jwtObj?.first_name.split(" ")[0][0] +
                                jwtObj?.last_name.split(" ")[0][0]}
                            </Typography>
                          </Avatar>
                        ) : (
                          <Avatar
                            src="/broken-image.jpg"
                            sx={{
                              width: 70,
                              height: 70,
                              mb: 2,
                              backgroundColor: theme.palette.warning.dark,
                            }}
                            color="inherit"
                          />
                        )}
                        <Typography component="span" variant="h4">
                          {jwtObj?.username}
                        </Typography>
                        <Typography
                          component="span"
                          variant="h4"
                          sx={{ fontWeight: 400 }}
                        >
                          {jwtObj?.email}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Divider />
                  </Box>
                  <Box
                    component="div"
                    sx={{
                      height: "100%",
                      maxHeight: "calc(100vh - 250px)",
                      overflowX: "hidden",
                    }}
                  >
                    <Box sx={{ px: 2, pb: 2 }}>
                      <List
                        component="nav"
                        sx={{
                          width: "100%",
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: "10px",
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%",
                          },
                          "& .MuiListItemButton-root": {
                            mt: 0.5,
                          },
                        }}
                      >
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          selected={selectedIndex === 0}
                          onClick={(event) =>
                            handleListItemClick(event, 0, "/user/account")
                          }
                        >
                          <ListItemIcon>
                            <IconSettings stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">
                                {t("account_settings")}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">
                                {t("logout")}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                  </Box>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default ProfileSection;
