import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { RESET_USER_PASSWORD } from "redux/authentication/action";
import jwt_decode from "jwt-decode";
import {
  getBrowserStorageAccessToken,
  getBrowserStorageRefreshToken,
  logout,
} from "utils/authentication";
import { logoutRefreshToken } from "redux/authentication/api";
import { useNavigate } from "react-router-dom";

const UpdatePassword = () => {
  const theme = useTheme();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordResetDialogOpen, setPasswordResetDialogOpen] = useState(false);
  const handlePasswordResetClickOpen = () => {
    setPasswordResetDialogOpen(true);
  };

  const handlePasswordResetClose = () => {
    setPasswordResetDialogOpen(false);
  };
  const handleResetPassword = () => {
    // getting users email address from token
    const accessToken = jwt_decode(getBrowserStorageAccessToken());
    const userEmail = accessToken?.email;

    dispatch({
      type: RESET_USER_PASSWORD,
      email: userEmail,
    });
    setPasswordResetDialogOpen(false);

    logoutRefreshToken(getBrowserStorageRefreshToken());
    logout();
    navigate("/login");
  };

  return (
    <>
      <Dialog
        open={passwordResetDialogOpen}
        onClose={handlePasswordResetClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h3">{"Send Password Reset Link"}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h4" sx={{ mb: 2, fontWeight: 500 }}>
              {"Are you sure you want to reset your password!"}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePasswordResetClose} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleResetPassword}
            variant="contained"
            color="error"
            autoFocus
          >
            Reset
          </Button>
        </DialogActions>
      </Dialog>

      <Container
        maxWidth="lg"
        sx={{
          height: "100%",
          mt: 5,
          p: 5,
          border: "1px solid" + theme.palette.grey[300],
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Typography
            variant="h3"
            fontFamily="monospace"
            sx={{ color: theme.palette.primary.main }}
          >
            Change Password
          </Typography>
          <Box
            sx={{
              pl: 2,
              mt: 2,
            }}
          >
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 500 }}>
              {
                "A link will be sent to you registered email with the Change Password link."
              }
            </Typography>
            <Typography variant="h5" color="error">
              {
                "*Please note that you will be logged out when you perform this operation."
              }
            </Typography>
          </Box>
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          mt={3}
        >
          <Button variant="contained" onClick={handlePasswordResetClickOpen}>
            {"Send Password Reset Link"}
          </Button>
        </Stack>
      </Container>
    </>
  );
};

export default UpdatePassword;
