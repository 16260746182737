import React from "react";
import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import MinimalLayout from "layout/MinimalLayout";

// login option 3 routing
const AuthLogin = Loadable(
  lazy(() => import("pages/authentication/AuthLogin"))
);
const AuthForgotPassword = Loadable(
  lazy(() => import("pages/authentication/AuthForgotPassword"))
);

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/login",
      element: <AuthLogin />,
    },
    {
      path: "/account/recover",
      element: <AuthForgotPassword />,
    },
  ],
};

export default AuthenticationRoutes;
