/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { Box, Button, Drawer, Tooltip, Typography } from "@mui/material";
import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here;
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import {
  MaterialReactTable,
  useMaterialReactTable,
  // MRT_FullScreenToggleButton,
  // MRT_ShowHideColumnsButton,
  // MRT_ToggleFiltersButton,
} from "material-react-table";

import { useDispatch, useSelector } from "react-redux";
import {
  BASE_COMPARE_COUNTRIES,
  GET_FILTERED_NGO_RECORDS,
  SET_FILTERED_NGO_RECORDS,
} from "redux/compareCountries/action";
import { namedRequestsInProgress } from "redux/serviceAndRequest/selector";

import NGOIssueListAndDetail from "./NGOIssueListAndDetail";
import { camel2title } from "utils/helper";

const TableView = (props) => {
  const { columns, rowData, tableDataLoading } = props;
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchData, setSearchData] = useState({
    country: "",
    indicatorClass: "",
    indicatorKey: "",
  });
  const { filteredNGORecords } = useSelector((state) => state.compareCountries);
  const { fromDateRange, toDateRange } = useSelector(
    (state) => state.compareCountries.search
  );

  let loading = useSelector((state) =>
    namedRequestsInProgress(state, {
      moduleName: BASE_COMPARE_COUNTRIES,
      requestName: SET_FILTERED_NGO_RECORDS,
    })
  );

  const handleExportData = () => {
    const csvConfig = mkConfig({
      filename: "compareCountries",
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });
    let data = JSON.parse(JSON.stringify(rowData));
    data = rowData.map((row) => {
      let id = row["id"];
      let indicatorKey = row["indicatorKey"];
      let indicator = row["indicator"];
      let subIndicator = row["subIndicator"];
      delete row["indicatorKey"];
      delete row["indicator"];
      delete row["subIndicator"];
      delete row["id"];

      let returnData = { id, indicator, subIndicator };
      Object.keys(row).map((item) => {
        if (
          row[item] === null ||
          ["number", "string"].includes(typeof row[item])
        ) {
          returnData[item] = row[item];
        } else if (["supplyChain", "humanRights"].includes(indicatorKey)) {
          returnData[item] = row[item]["total"];
        } else {
          returnData[item] = row[item]["risk_level"];
        }
      });

      return returnData;
    });
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  let table = useMaterialReactTable({
    columns: columns,
    data: rowData,
    state: {
      isLoading: tableDataLoading,
      showProgressBars: tableDataLoading,
      showLoadingOverlay: false,
    },
    // muiCircularProgressProps: {
    //   color: "secondary",
    // },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Download CSV
        </Button>
      </Box>
    ),
    enableColumnResizing: true,
    columnResizeMode: "onEnd",
    enableGlobalFilter: false,
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableStickyHeader: true,
    enableGrouping: true,
    enableDensityToggle: false,
    enableFilters: false,
    enableSorting: false,
    enableRowVirtualization: true,
    rowVirtualizerOptions: { overscan: 15, estimateSize: () => 77 },
    // enableColumnVirtualization: true,
    enablePagination: false,
    enableBottomToolbar: false,

    initialState: {
      density: "compact",
      expanded: true,
      columnPinning: { left: ["indicator", "subIndicator"] },
      grouping: ["indicator"],
    },
    muiToolbarAlertBannerChipProps: { color: "primary" },
    muiTablePaperProps: { sx: { height: "100%" } },
    muiTableContainerProps: {
      sx: { height: "calc(100% - 68.5px) !important", overflow: "auto" },
    },
    muiTableProps: {
      sx: (theme) => ({
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        height: "100%",
      }),
    },
    muiTableBodyCellProps: ({ cell }) => ({
      onClick: (e) => {
        if (
          ["humanRightsRisk", "supplyChainRisk"].includes(
            cell.row.original[cell.column.id].id
          ) &&
          !("groupingColumnId" in cell.row)
        ) {
          dispatch({
            type: GET_FILTERED_NGO_RECORDS,
            payload: {
              country: cell.row.original[cell.column.id].country_no,
              indicatorClass: cell.row.original[cell.column.id].id,
              indicatorKey: cell.row.original[cell.column.id].action_no,
              startDate: fromDateRange.toISOString(),
              endDate: toDateRange.toISOString(),
            },
          });
          setSearchData({
            country: cell.column.columnDef.header,
            indicatorClass: cell.row.original[cell.column.id].id,
            indicatorKey: cell.row.original.subIndicator,
          });
          setOpenDrawer(true);
        }
      },
      sx: {
        cursor: "pointer", //you might want to change the cursor too when adding an onClick
      },
    }),
    positionToolbarAlertBanner: "none",
    positionToolbarDropZone: "none",
  });

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Drawer
        anchor={"right"}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Box sx={{ width: "700px" }}>
          <NGOIssueListAndDetail
            reportList={filteredNGORecords}
            loading={loading}
            titleText={`NGO Actions - ${camel2title(
              searchData.indicatorClass
            )}  [${searchData.indicatorKey}]  - ${searchData.country} `}
          />
        </Box>
      </Drawer>
      <MaterialReactTable table={table}></MaterialReactTable>
    </Box>
  );
};

export default TableView;

TableView.propTypes = {
  columns: PropTypes.array,
  rowData: PropTypes.array,
  tableDataLoading: PropTypes.bool,
};
