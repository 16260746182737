/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Divider from "@mui/material/Divider";

import { HtmlTooltip } from "ui-component/HtmlToolTip";

//assets
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

const CO2EmissionChart = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [series, setSeries] = useState([]);
  const [option, setOption] = useState({});
  const { co2_emission: co2Emission } = useSelector(
    (state) => state.countryRisk.countryStatistics
  );

  let chartTitle = t("co2EmissionChartTitleI18n");
  let yAxisName = t("co2EmissionYAxisNameI18n");
  let xAxisName = t("yearI18n");
  let infoText = t("co2EmissionInfoTextI18n");

  useEffect(() => {
    if (co2Emission) {
      let seriesData = Object.values(co2Emission);
      setSeries([
        {
          name: yAxisName,
          data: seriesData,
        },
      ]),
        setOption({
          chart: {
            toolbar: {
              show: false,
            },
            sparkline: {
              enabled: true,
            },
          },
          // title: {
          // text: `${yAxisName} =
          //   ${seriesData[seriesData.length - 1]} kt
          // `,

          //   style: {
          //     color: theme.palette.text.primary,
          //   },
          // },
          dataLabels: {
            enabled: false,
          },
          colors: [theme.palette.chart[800]],
          stroke: {
            width: 3,
            curve: "straight",
          },
          xaxis: {
            title: {
              text: xAxisName,
            },
            categories: Object.keys(co2Emission),
            tickPlacement: "on",
          },
          yaxis: {
            forceNiceScale: true,
            labels: {
              show: false,
              formatter: function (val) {
                return val + " kt";
              },
            },
          },
        });
    }
  }, [co2Emission, yAxisName, xAxisName]);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      {option && series.length !== 0 && (
        <Chart
          options={option}
          series={series}
          type="area"
          height="100%"
          width="100%"
        />
      )}
    </Box>
  );
};

export default CO2EmissionChart;
