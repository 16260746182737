import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import reducer from "./reducer";
import rootSaga from "./saga";
// ==============================|| REDUX - MAIN STORE ||============================== //

// create saga middleware
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);
const persister = "Free";

// running the saga
sagaMiddleware.run(rootSaga);

export { store, persister };
