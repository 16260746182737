import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

import { PropTypes } from "prop-types";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
// import { useEffect } from "react";

// SidePanelService.js

const InfoSidePanel = (props) => {
  const { title, body, isOpen, onClose, containerSx = {}, bodySx = {} } = props;
  // useEffect(() => {
  //   console.log(title, body, isOpen, onClose);
  // }, [props]);
  return (
    <Drawer
      anchor={"right"}
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: "400px",
          p: 1.5,
          ...containerSx,
        },
      }}
    >
      {/* title */}
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4">{title}</Typography>
        <IconButton onClick={onClose} aria-label="close information ">
          <CloseOutlinedIcon />
        </IconButton>
      </Stack>
      <Divider sx={{ pt: 2 }} />
      {/* body */}
      <Box sx={{ p: 2, ...bodySx }}>
        <Typography>{body}</Typography>
      </Box>
    </Drawer>
  );
};

InfoSidePanel.propTypes = {
  title: PropTypes.string,
  body: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  containerSx: PropTypes.object,
  bodySx: PropTypes.object,
};
export default InfoSidePanel;
