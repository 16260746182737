import { IconButton, useTheme } from "@mui/material";
import React from "react";

import PropTypes from "prop-types";

import InfoIcon from "@mui/icons-material/Info";
import InfoSidePanelService from "services/InfoSidePanelService";

const InfoButton = (props) => {
  const { title, bodyComponent, size = "xs" } = props;
  const theme = useTheme();
  const infoSidePanel = InfoSidePanelService();

  return (
    <>
      <IconButton
        aira-label="information"
        size={size}
        onClick={() => {
          infoSidePanel.openInfoSidePanel(title, bodyComponent);
        }}
      >
        <InfoIcon fontSize="inherit" color={theme.palette.grey[500]} />
      </IconButton>
      {infoSidePanel.InfoSidePanelComponent}
    </>
  );
};

InfoButton.propTypes = {
  title: PropTypes.string,
  bodyComponent: PropTypes.node,
  size: PropTypes.string,
};
export default InfoButton;
