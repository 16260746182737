import React from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import WestIcon from "@mui/icons-material/West";

import notFound from "../../assets/images/not-found.svg";
import config from "config";

const NotFound = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-evenly"
      sx={{
        p: { xs: 1, lg: 7, xl: 14 },
        width: "100%",
        height: `calc( 100vh - ${config.headerHeight} )`,
      }}
    >
      <Grid item xs={5} sx={{ width: "100%", height: "100%" }}>
        <Grid
          sx={{ width: "100%", height: "100%" }}
          container
          direction="column"
          alignItems="start"
          justifyContent="space-evenly"
        >
          <Stack>
            <Typography
              variant={"h1"}
              sx={{
                fontSize: { xs: "5rem", lg: "7rem", xl: "10rem" },
                color: theme.palette.error.dark,
              }}
            >
              404
            </Typography>
            <Typography
              variant={"h1"}
              sx={{
                fontSize: { xs: "2rem", lg: "2.8rem", xl: "4rem" },
                color: theme.palette.grey["900"],
              }}
            >
              Page not found
            </Typography>
          </Stack>

          <Button
            variant="outlined"
            startIcon={<WestIcon />}
            onClick={() => navigate("/")}
          >
            Back to Homepage
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={4} sx={{ width: "100%", height: "100%" }}>
        <img width="100%" height="100%" src={notFound} alt=" 404 not found" />
      </Grid>
    </Grid>
  );
};

export default NotFound;
