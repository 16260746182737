import PropTypes from "prop-types";
import { forwardRef } from "react";

// material-ui
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import useTheme from "@mui/material/styles/useTheme";

// constant
const headerSX = {
  p: 0,
  py: 2,
  "& .MuiCardHeader-action": { mr: 0 },
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentClass = "",
      contentSX = {},
      titleText,
      secondary,
      shadow,
      sx = {},
      title,
      loading = false,
      ...others
    },
    ref
  ) => {
    const theme = useTheme();

    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          border: border ? "1px solid" : "none",
          borderColor: theme.palette.grey[200],
          ":hover": {
            boxShadow: boxShadow
              ? shadow || "0 2px 14px 0 rgb(32 40 45 / 8%)"
              : "inherit",
          },
          ...sx,
        }}
      >
        {/* heading loading */}
        {loading && title && (
          <Box
            sx={{
              height: "65px",
              p: 2,
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Skeleton
              variant="rectangular"
              sx={{ height: "50%", width: "60%" }}
            ></Skeleton>
          </Box>
        )}
        {/* card header and action */}

        {titleText && title && !loading && (
          <CardHeader
            sx={headerSX}
            title={
              <Typography
                variant="h1"
                sx={{
                  color: "#003153",
                  fontWeight: "700",
                }}
              >
                {titleText}
              </Typography>
            }
            action={secondary}
          />
        )}

        {/* content & header divider */}
        {title && (
          <Divider
            sx={{
              borderBottomWidth: 2,
              width: "100%",
              borderColor: theme.palette.grey[500],
            }}
          />
        )}

        {/* card content */}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  }
);

MainCard.displayName = "MainCard";

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  children: PropTypes.node,
  content: PropTypes.bool,
  loading: PropTypes.bool,
  contentClass: PropTypes.string,
  contentSX: PropTypes.object,
  titleText: PropTypes.string,
  secondary: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
  ]),
  shadow: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.bool,
};

export default MainCard;
