import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

import { calculateChartMultilineName } from "utils/helper";

//assets
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { useTranslation } from "react-i18next";
import CustomCard from "ui-component/card";

const SDGTargetChart = (props) => {
  const { loading } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const { sdgGoalTarget } = useSelector(
    (state) => state.countryRisk.countryStatistics
  );
  const [series, setSeries] = useState([]);
  const [option, setOption] = useState({});
  const [goalCategory, setGoalCategoty] = useState("");
  let [goalCategoryOption, setGoalCategotyOption] = useState([]);

  let chartTitle = t("SDGTargetChartTitleI18n");
  let xAxisName = t("SDGTargetChartXAxisNameI18n");
  let yAxisName = t("SDGTargetChartYAxisNameI18n");

  useEffect(() => {
    if (sdgGoalTarget?.target) {
      setGoalCategotyOption(Object.keys(sdgGoalTarget.target));
      setGoalCategoty(Object.keys(sdgGoalTarget.target)[0]);
    }
  }, [sdgGoalTarget?.target]);
  useEffect(() => {
    if (goalCategory) {
      setSeries([
        {
          name: yAxisName,
          data: sdgGoalTarget.target[goalCategory].map((data) => data.count),
        },
      ]);
      setOption({
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: [
          "#ffa600",
          "#ff7c43",
          "#f95d6a",
          "#d45087",
          "#a05195",
          "#665191",
          "#2f4b7c",
          "#003f5c",
        ],

        plotOptions: {
          bar: {
            borderRadius: 2,
            horizontal: true,
            distributed: true,
            barHeight: "60%",
            dataLabels: {
              position: "top",
            },
          },
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
        },
        stroke: {
          width: 2,
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: [theme.palette.grey[900]],
          },
          textAnchor: "end",
          offsetX: 40,
          background: {
            enabled: false,
          },
        },
        tooltip: {
          fixed: {
            enabled: true,
            position: "topRight",
          },
        },
        yaxis: {
          labels: {
            show: true,
            minWidth: 250,
            maxWidth: 300,
            style: {
              fontSize: "10px",
              fontWeight: 600,
            },
          },
          title: {
            text: yAxisName,
            style: {
              fontSize: "12px",
              fontWeight: 800,
            },
          },
          forceNiceScale: false,
          show: true,
        },

        xaxis: {
          title: {
            text: xAxisName,
            style: {
              fontSize: "10px",
              fontWeight: 800,
            },
          },
          tickAmount: 4,
          tickPlacement: "on",
          max: calcualteMaxForXAxis(),
          // categories: sdgGoalTarget.target[goalCategory].map(
          //   (data) => data.name
          // ),
          categories: calculateChartMultilineName(
            sdgGoalTarget.target[goalCategory].map((data) => data.name),
            50
          ),
          labels: {
            show: true,
            style: {
              fontSize: "10px",
              fontWeight: 600,
            },
          },
        },
      });
    }
  }, [goalCategory, xAxisName, yAxisName]);

  const calcualteMaxForXAxis = () => {
    let result =
      Math.max(
        ...sdgGoalTarget.target[goalCategory].map((data) => data.count)
      ) + 50;
    return result + 50 - (result % 50);
  };

  return (
    <CustomCard
      titleText={chartTitle}
      titleInfoText={
        t("SDGTargetChartInfo1I18n") + t("SDGTargetChartInfo2I18n")
      }
      loading={loading}
      noData={Object.keys(sdgGoalTarget.target)?.length === 0}
    >
      {option && series.length !== 0 && (
        <Box sx={{ height: "87%", width: "100%" }}>
          <Chart
            options={option}
            series={series}
            type="bar"
            height="100%"
            width="100%"
          />
        </Box>
      )}
      {/* filter */}
      <Grid
        item
        xs={12}
        container
        sx={{
          mt: 2,
          height: "13%",
          backgroundColor: theme.palette.grey[300],
          borderTop: "2px solid rgba(0,0,0,0.4)",
          position: "relative",
        }}
      >
        <Accordion
          sx={{
            position: "absolute",
            bottom: 0,
            backgroundColor: theme.palette.grey[300],
            width: "100%",
          }}
        >
          <AccordionSummary expandIcon={<ExpandLessIcon />}>
            <Typography
              variant="h4"
              sx={{ fontWeight: 700, color: theme.colors?.textDark }}
            >
              {t("filterI18n")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              item
              xs={4}
              container
              direction="column"
              sx={{ height: "100%", width: "100%" }}
            >
              {goalCategory && goalCategoryOption.length && (
                <>
                  <Typography
                    variant="h5"
                    sx={{ color: theme.palette.text.primary, pl: 1, pb: 0.4 }}
                  >
                    {t("SDGGoalChartXAxisNameI18n")}
                  </Typography>
                  <FormControl
                    variant="outlined"
                    sx={{ minWidth: 150, height: "100%" }}
                  >
                    <Select
                      sx={{ height: "30px" }}
                      value={goalCategory}
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={(d) => setGoalCategoty(d.target.value)}
                    >
                      {goalCategoryOption.map((opt, idx) => {
                        return (
                          <MenuItem key={idx} value={opt}>
                            {opt}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </CustomCard>
  );
};

export default SDGTargetChart;

SDGTargetChart.propTypes = {
  loading: PropTypes.bool,
};
