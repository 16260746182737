/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";

// mui

// import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
// import useTheme from "@mui/material/styles/useTheme";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

// ui-component
// import { HtmlTooltip } from "ui-component/HtmlToolTip";

// assets
import { Box, Link, Typography } from "@mui/material";
import InfoButton from "ui-component/infoButton";

// import { InfoOutlined } from "@mui/icons-material";

const WorldMapTitleAndCategorySelect = (props) => {
  const {
    countryRiskCategory,
    countryRiskCategoryList,
    setCountryRiskCategory,
  } = props;
  // const theme = useTheme();

  const handleChange = (event) => {
    setCountryRiskCategory(event.target.value);
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="flex-start"
    >
      <FormControl size="small">
        <Select value={countryRiskCategory} onChange={handleChange}>
          {countryRiskCategoryList?.map((item) => {
            return (
              <MenuItem key={item.key} value={item.key}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <InfoButton
        size={"small"}
        title={
          countryRiskCategoryList.filter(
            (item) => item.key === countryRiskCategory
          )[0]?.name
        }
        bodyComponent={
          <Box>
            <Typography variant="body1">
              {
                countryRiskCategoryList.filter(
                  (item) => item.key === countryRiskCategory
                )[0]?.explaination_en
              }
            </Typography>
            <Link
              href={
                countryRiskCategoryList.filter(
                  (item) => item.key === countryRiskCategory
                )[0]?.link
              }
              rel="noreferrer"
              target="_blank"
            >
              <Typography
                variant="h5"
                sx={{ mt: 2, fontWeight: "700" }}
              >{`Source :  ${
                countryRiskCategoryList.filter(
                  (item) => item.key === countryRiskCategory
                )[0]?.source
              }`}</Typography>
            </Link>
          </Box>
        }
      ></InfoButton>
    </Stack>
  );
};
export default WorldMapTitleAndCategorySelect;
WorldMapTitleAndCategorySelect.propTypes = {
  countryRiskCategoryList: PropTypes.array,
  countryRiskCategory: PropTypes.string,
  setCountryRiskCategory: PropTypes.func,
};

// <Typography variant="h4">
//               {
//                 countryRiskCategoryList.filter(
//                   (item) => item.key === countryRiskCategory
//                 )[0]?.name?.explaination_en
//               }
//             </Typography>
//             <Link
//               href={
//                 countryRiskCategoryList.filter(
//                   (item) => item.key === countryRiskCategory
//                 )[0]?.name?.link
//               }
//               rel="noreferrer"
//               target="_blank"
//             >
//               <Typography
//                 variant="h5"
//                 sx={{ mt: 2, fontWeight: "700" }}
//               >{`Source :  ${
//                 countryRiskCategoryList.filter(
//                   (item) => item.key === countryRiskCategory
//                 )[0]?.name?.source
//               }`}</Typography>
//             </Link>
