/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Divider from "@mui/material/Divider";

import { HtmlTooltip } from "ui-component/HtmlToolTip";

//assets
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import { numFormatter } from "utils/helper";
import { Box } from "@mui/material";

const GDPPerCapitaChart = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [series, setSeries] = useState([]);
  const [option, setOption] = useState({});
  const { language } = useSelector((state) => state.customization);

  let yAxisName = t("GDPYAxisNameI18n");
  let xAxisName = t("yearI18n");

  const { gdp } = useSelector((state) => state.countryRisk.countryStatistics);

  useEffect(() => {
    if (gdp) {
      let seriesData = Object.values(gdp);
      setSeries([
        {
          name: yAxisName,
          data: seriesData,
        },
      ]),
        setOption({
          chart: {
            toolbar: {
              show: false,
            },
            sparkline: {
              enabled: true,
            },
          },
          // title: {
          //   text: `${yAxisName} = ${numFormatter(
          //     seriesData[seriesData.length - 1],
          //     language
          //   )}$`,
          //   style: {
          //     color: theme.palette.text.primary,
          //   },
          // },
          dataLabels: {
            enabled: false,
          },

          colors: [theme.palette.chart[800]],
          stroke: {
            width: 3,
            curve: "straight",
          },
          xaxis: {
            categories: Object.keys(gdp),
            tickPlacement: "on",
          },
          yaxis: {
            labels: {
              show: false,
              formatter: function (val) {
                return numFormatter(val, language) + "$";
              },
            },
            // title: {
            //   text: yAxisName + " ( $ )",
            // },
          },
        });
    }
  }, [gdp, yAxisName, language]);

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      {option && series.length !== 0 && (
        <Chart
          options={option}
          series={series}
          type="area"
          height="100%"
          width="100%"
        />
      )}
    </Box>
  );
};

export default GDPPerCapitaChart;
