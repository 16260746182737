import React, { useState } from "react";
import InfoSidePanel from "./InfoSidePanel";

const InfoSidePanelService = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState(null);

  const openInfoSidePanel = (title, bodyComponent) => {
    setTitle(title);
    setBody(bodyComponent);
    setIsOpen(true);
  };

  const closeInfoSidePanel = () => {
    // console.log("close is triggered");
    setIsOpen(false);
    setTitle("");
    setBody(null);
  };

  const InfoSidePanelComponent = (
    <InfoSidePanel
      isOpen={isOpen}
      title={title}
      body={body}
      onClose={closeInfoSidePanel}
    />
  );
  return {
    openInfoSidePanel,
    InfoSidePanelComponent,
  };
};
export default InfoSidePanelService;
