import React from "react";
import { Navigate } from "react-router-dom";
import { getBrowserStorageAccessToken } from "utils/authentication";
import PropTypes from "prop-types";

const ProtectedRoute = ({ children }) => {
  let token = getBrowserStorageAccessToken();
  return token ? children : <Navigate to="/login" />;
};

ProtectedRoute.propTypes = {
  children: PropTypes.element,
};
export default ProtectedRoute;
