/* eslint-disable react/prop-types */
// mui
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import { Chip, Divider } from "@mui/material";

// assets
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
import SentimentNeutralOutlinedIcon from "@mui/icons-material/SentimentNeutralOutlined";

export const NGOReportAccordion = (props) => {
  const { issue } = props;
  const theme = useTheme();
  return (
    <Accordion sx={{ mb: 1, width: "100%" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          backgroundColor: theme.palette.grey[100],
          width: "100%",
          "&>.MuiAccordionSummary-content": {
            width: "100%",
          },
        }}
      >
        <Stack direction="column" sx={{ width: "100%" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              spacing={2}
              divider={<Divider orientation="vertical" flexItem />}
              sx={{ width: "100%", overflowX: "scroll" }}
            >
              <Tooltip title="Sentiment" arrow>
                {issue.sentiment === "neutral" ? (
                  <SentimentNeutralOutlinedIcon
                    sx={{ color: theme.palette.grey[400], fontSize: "14px" }}
                  />
                ) : issue.sentiment === "positive" ? (
                  <ThumbUpOffAltOutlinedIcon
                    sx={{ color: theme.palette.success.main, fontSize: "14px" }}
                  />
                ) : (
                  <ThumbDownOffAltOutlinedIcon
                    sx={{ color: theme.palette.error.main, fontSize: "14px" }}
                  />
                )}
              </Tooltip>
              <Tooltip title="Campaign Date" arrow>
                <Typography
                  sx={{
                    fontWeight: "400",
                    color: theme.palette.text.primary,
                    fontSize: "12px",
                  }}
                >
                  {new Date(issue.date).toLocaleDateString()}
                </Typography>
              </Tooltip>
              {issue.company !== null && (
                <Tooltip title="Company" arrow>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      color: theme.palette.text.primary,
                      fontSize: "12px",
                    }}
                  >
                    {issue.company}
                  </Typography>
                </Tooltip>
              )}
            </Stack>
            <Chip
              color={"primary"}
              variant="outlined"
              size="xs"
              sx={{}}
              label={issue?.tag === "NGO" ? "NGO-ACTION" : "NEWS-ARTICLE"}
            />
          </Stack>
          <Typography variant="h5" sx={{ fontWeight: 500, mt: 0.5 }}>
            {`${issue.headline}`}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {issue?.tag === "NGO" && (
          <Box
            sx={{
              height: "auto",
              border: "1px solid " + theme.palette.grey[200],
            }}
          >
            <Grid
              container
              sx={{
                width: "100%",
                height: "100%",
                p: 3,
              }}
              rowSpacing={1}
            >
              {/* title */}
              <Grid item xs={4}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: theme.palette.grey[700] }}
                >
                  {"Title"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {issue?.headline}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* campaign date */}
              <Grid item xs={4}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: theme.palette.grey[700] }}
                >
                  {"Campaign Date"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {issue.date}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* campaign date */}
              <Grid item xs={4}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: theme.palette.grey[700] }}
                >
                  {"Risk Level"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {issue.sentiment
                  ? issue.sentiment[0].toUpperCase() +
                    issue.sentiment.substring(1)
                  : ""}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* Industry Sector */}
              <Grid item xs={4}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: theme.palette.grey[700] }}
                >
                  {"Industry Sector"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {issue?.sector}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* Key Findings */}

              <Grid item xs={4}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: theme.palette.grey[700] }}
                >
                  {"Key Findings"}
                </Typography>
              </Grid>

              <Grid item xs={8}>
                {issue?.materiality}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              {/* Human Rights Issue Category */}
              <Grid item xs={4}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: theme.palette.grey[700] }}
                >
                  {"Human Rights Issue Category"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {issue?.issue}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* campaign overview */}
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: theme.palette.grey[700] }}
                >
                  {"Campaign Overview"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {issue?.report || "-"}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* Reference */}
              <Grid item xs={4}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: theme.palette.grey[700] }}
                >
                  {"Reference"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {issue?.link ? (
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Link
                      href={issue?.link}
                      // color="primary"
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                    >
                      <Typography>{` Link`}</Typography>{" "}
                    </Link>
                    <Typography sx={{ fontSize: "10px" }}>
                      {" "}
                      {"* Possibility of a broken link occurance "}
                    </Typography>
                  </Stack>
                ) : (
                  "-"
                )}{" "}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* NGO Main Activity */}
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: theme.palette.grey[700] }}
                >
                  {"NGO Main Activity"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={2}
                  divider={<Divider orientation="vertical" flexItem />}
                  alignItems="center"
                >
                  {issue?.ngo_logo !== null && (
                    <Box
                      sx={{
                        width: "100%",
                        height: "150px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                        alt="none"
                        loading="lazy"
                        src={issue?.ngo_logo}
                      />
                    </Box>
                  )}
                  <Box>{issue?.ngo_campaign_activity || "-"}</Box>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* NGO  Loaction */}
              <Grid item xs={4}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: theme.palette.grey[700] }}
                >
                  {"NGO Main Loaction"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {issue?.ngo_location || "-"}
              </Grid>
            </Grid>
          </Box>
        )}
        {issue?.tag === "NEWS" && (
          <Box
            sx={{
              height: "auto",
              border: "1px solid " + theme.palette.grey[200],
            }}
          >
            <Grid
              container
              sx={{
                width: "100%",
                height: "100%",
                p: 3,
              }}
              rowSpacing={1}
            >
              {/* title */}
              <Grid item xs={4}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: theme.palette.grey[700] }}
                >
                  {"Title"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {issue?.headline}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* campaign date */}
              <Grid item xs={4}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: theme.palette.grey[700] }}
                >
                  {"Campaign Date"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {issue.date}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* campaign date */}
              <Grid item xs={4}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: theme.palette.grey[700] }}
                >
                  {"Risk Level"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {issue.sentiment
                  ? issue.sentiment[0].toUpperCase() +
                    issue.sentiment.substring(1)
                  : ""}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* Industry Sector */}
              <Grid item xs={4}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: theme.palette.grey[700] }}
                >
                  {"Industry Sector"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {issue?.sector}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              {/* Human Rights Issue Category */}
              <Grid item xs={4}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: theme.palette.grey[700] }}
                >
                  {"Human Rights Issue Category"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {issue?.issue}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* Article summary */}
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: theme.palette.grey[700] }}
                >
                  {"Article Summary"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {issue?.report || "-"}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* Reference */}
              <Grid item xs={4}>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600", color: theme.palette.grey[700] }}
                >
                  {"Reference"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {issue?.link ? (
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Link
                      href={issue?.link}
                      // color="primary"
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                    >
                      <Typography>{` Link`}</Typography>{" "}
                    </Link>
                    <Typography sx={{ fontSize: "10px" }}>
                      {" "}
                      {"* Possibility of a broken link occurance "}
                    </Typography>
                  </Stack>
                ) : (
                  "-"
                )}{" "}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
