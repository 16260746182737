import { Box, Typography, useTheme } from "@mui/material";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_COUNTRY_LIST } from "redux/countryRisk/action";
import config from "config";
import { ChangeCountry } from "./Detail/components";

const DefaultDetailCountrySelector = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { countryList } = useSelector((state) => state.countryRisk);
  // translatation
  const emptyText = "Please select a country to view Risk ";
  useEffect(() => {
    if (countryList.length === 0) dispatch({ type: GET_COUNTRY_LIST });
  }, [dispatch]);

  return (
    <Box
      sx={{
        width: "100%",
        height: `calc( 100vh - ${config.headerHeight})`,
      }}
    >
      <Typography
        variant="h2"
        sx={{
          color: theme.palette.text.primary,
          fontStyle: "italic",
          fontWeight: 700,
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {emptyText}
      </Typography>
      <ChangeCountry countryList={countryList} countryAlpha3Code={"CHN"} />
    </Box>
  );
};

export default DefaultDetailCountrySelector;
