/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  Stack,
  Tooltip,
  // Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { HtmlTooltip } from "ui-component/HtmlToolTip";

import Chart from "react-apexcharts";

const SentimentCellRenderer = (props) => {
  const theme = useTheme();
  const { series, total } = props;

  const option = {
    chart: {
      animations: {
        enabled: false,
      },
      type: "bar",
      stacked: true,
      stackType: "100%",
      sparkline: {
        enabled: true,
      },
    },
    tooltip: {
      enabled: false,
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: [theme.palette.background.paper],
      },
      formatter: (val) => {
        return ((val * total) / 100).toFixed(0);
      },
    },
    colors: [
      theme.palette.success.dark,
      theme.palette.grey[400],
      theme.palette.error.dark,
    ],
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <Box
      sx={{
        height: "100%",
        minHeight: "30px",
        width: "100%",
        px: 2,
        border: "1px solid " + theme.palette.grey[300],
      }}
    >
      {total && (
        <HtmlTooltip title="Total Records ---- Sentiment (Positive | Neutral | Negative)">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            height="100%"
          >
            <Typography
              component="span"
              sx={{ fontWeight: 700, fontFamily: "monospace", width: "20%" }}
            >
              {total}
            </Typography>

            <Box
              sx={{
                height: "55%",
                width: "80%",
                display: "flex",
                aligItems: "center",
                justifyContent: "center",
                my: 1,
              }}
            >
              {series[0].data[0] !== 0 && (
                <Box
                  sx={{
                    width: `${(series[0].data[0] / total) * 100}%`,
                    height: "100%",
                    background: theme.palette.success.dark,
                    display: "flex",
                    aligItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: theme.palette.grey[50],
                    }}
                  >
                    {series[0].data[0]}
                  </Typography>
                </Box>
              )}
              {series[1].data[0] !== 0 && (
                <Box
                  sx={{
                    width: `${(series[1].data[0] / total) * 100}%`,
                    height: "100%",
                    background: theme.palette.grey[500],
                    display: "flex",
                    aligItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: theme.palette.grey[50],
                    }}
                  >
                    {series[1].data[0]}
                  </Typography>
                </Box>
              )}
              {series[2].data[0] !== 0 && (
                <Box
                  sx={{
                    width: `${(series[2].data[0] / total) * 100}%`,
                    height: "100%",
                    background: theme.palette.error.dark,
                    display: "flex",
                    aligItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: theme.palette.grey[50],
                    }}
                  >
                    {series[2].data[0]}
                  </Typography>
                </Box>
              )}
            </Box>
          </Stack>
        </HtmlTooltip>
      )}
      {!total && (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%", height: "100%" }}
        >
          {"-"}
        </Stack>
      )}
    </Box>
  );
};

export default SentimentCellRenderer;

SentimentCellRenderer.propTypes = {
  series: PropTypes.array,
  total: PropTypes.number,
};
