import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
// import RiskEvalChart from "./riskEvalChart";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RiskEvalChart, SectorWiseChart } from "./components";
// eslint-disable-next-line no-unused-vars

const InsightView = () => {
  const selectCountryLabel = "Change Country";
  const theme = useTheme();
  const { selectedCountries: storeSelectedCountryList } = useSelector(
    (state) => state.compareCountries.search
  );
  const [selectedCountry, setSelectedCountry] = useState("");
  useEffect(() => {
    if (storeSelectedCountryList.length > 0) {
      setSelectedCountry(storeSelectedCountryList[0]["alpha3_code"]);
    }
  }, [storeSelectedCountryList]);
  return (
    <Box sx={{ height: "100%", width: "100%", px: 5, overflowY: "scroll" }}>
      <Box sx={{ mt: 2 }}>
        <InputLabel
          required={true}
          id="country-select-label"
          htmlFor="country-select"
          sx={{
            fontFamily: "monospace",
            color: theme.palette.grey[900],
            fontSize: 15,
            pl: 1,
            mb: 0.75,
          }}
        >
          {selectCountryLabel}
        </InputLabel>
        <FormControl size="small" sx={{ minWidth: 200 }}>
          <Select
            id="country-select"
            value={selectedCountry}
            onChange={(e) => {
              setSelectedCountry(e.target.value);
            }}
          >
            {storeSelectedCountryList.map((countryObj, idx) => {
              return (
                <MenuItem key={idx} value={countryObj.alpha3_code}>
                  {countryObj.country}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>

      <Grid container sx={{ height: "auto", width: "100%", mt: 2 }}>
        <Grid item xs={12} sx={{ height: 500, mb: 2 }}>
          {/* <RiskEvalChart /> */}
          <RiskEvalChart countryAlpha3Code={selectedCountry} />
        </Grid>
        <Grid item xs={12} sx={{ height: "auto", mb: 2 }}>
          <SectorWiseChart countryAlpha3Code={selectedCountry} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default InsightView;
