import PropTypes from "prop-types";

// ==============================|| LOGO SVG ||============================== //
const Logo = ({ color }) => {
  const defaultColor = "#fffff";
  return (
    <svg width="36" height="42" viewBox="0 0 36 42" fill="none">
      <line
        x1="16.5"
        y1="6"
        x2="16.5"
        y2="42"
        stroke={color ? color : defaultColor}
        strokeWidth="3"
      />
      <line
        x1="11.5"
        y1="10"
        x2="11.5"
        y2="42"
        stroke={color ? color : defaultColor}
        strokeWidth="3"
      />
      <path
        d="M2.54545 17H7C7 17 7 27.0993 7 32.3191C7 37.539 4.375 41 0 41"
        stroke={color ? color : defaultColor}
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 19.7084C20.815 18.785 21.8399 18.0511 23 17.5815V0H20V19.7084Z"
        fill={color ? color : defaultColor}
      />
      <path
        d="M27.875 39.75L30.3648 35.4375H25.3852L27.875 39.75Z"
        fill={color ? color : defaultColor}
      />
      <line
        x1="21"
        y1="27"
        x2="21"
        y2="42"
        stroke={color ? color : defaultColor}
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 34C32.4183 34 36 30.4183 36 26C36 21.5817 32.4183 18 28 18C23.5817 18 20 21.5817 20 26C20 30.4183 23.5817 34 28 34ZM28 32C31.3137 32 34 29.3137 34 26C34 22.6863 31.3137 20 28 20C24.6863 20 22 22.6863 22 26C22 29.3137 24.6863 32 28 32Z"
        fill={color ? color : defaultColor}
      />
    </svg>
  );
};

Logo.propTypes = {
  color: PropTypes.string,
};

Logo.defaultProps = {
  color: null,
};

export default Logo;
