import mem from "mem";
import { getBrowserStorageRefreshToken } from "utils/authentication";
import config from "config";
import { getRefreshedAccessToken } from "redux/authentication/api";

const logout = (navigate) => {
  localStorage.removeItem(config.JWTAccessToken);
  localStorage.removeItem(config.JWTRefreshToken);
  navigate("/login");
};

const refreshTokenFn = async (navigate) => {
  const refreshToken = getBrowserStorageRefreshToken();
  try {
    if (refreshToken) {
      const token = await getRefreshedAccessToken(refreshToken);
      return token;
    } else logout(navigate);
  } catch (error) {
    logout(navigate);
  }
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});
