export const BASE_COMPARE_COUNTRIES = "@compare_countries";
export const GET_COUNTRIES_STATISTICS =
  BASE_COMPARE_COUNTRIES + "/GET_COUNTRIES_STATISTICS";
export const SET_COUNTRIES_STATISTICS =
  BASE_COMPARE_COUNTRIES + "/SET_COUNTRIES_STATISTICS";
export const SET_SELECTED_COUNTRIES =
  BASE_COMPARE_COUNTRIES + "/SET_SELECTED_COUNTRIES";
export const CLEAR_COUNTRIES_STATISCS =
  BASE_COMPARE_COUNTRIES + "/CLEAR_COUNTRIES_STATISCS";
// fetching ngo reports
export const GET_FILTERED_NGO_RECORDS =
  BASE_COMPARE_COUNTRIES + "/GET_FILTERED_NGO_RECORDS";
export const SET_FILTERED_NGO_RECORDS =
  BASE_COMPARE_COUNTRIES + "/SET_FILTERED_NGO_RECORDS";
