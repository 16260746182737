/* eslint-disable no-unused-vars */
// material-ui
import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { ClimbingBoxLoader, HashLoader } from "react-spinners";
import styled from "@mui/material/styles/styled";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";

// styles
const LoaderWrapper = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 1301,
  width: "100%",
});

// ==============================|| LOADER ||============================== //
const Loader = () => {
  const theme = useTheme();
  return (
    <>
      <LoaderWrapper>
        <LinearProgress color="primary" />
      </LoaderWrapper>
      <Box
        sx={{
          display: "flex",
          direction: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "90vh",
        }}
      >
        <HashLoader
          loading={true}
          color={theme.palette.primary[200]}
          size={150}
          // cssOverride={override}
        />
      </Box>
    </>
  );
};

export default Loader;
