import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ResponsiveCirclePacking } from "@nivo/circle-packing";
import CustomCard from "ui-component/card";
import { Box, ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { objectIsEmpty } from "utils/helper";

const SDGBubbleChartNivo = (props) => {
  const { loading } = props;
  const theme = useTheme();
  const [zoomedId, setZoomedId] = useState(null);
  const [leavesOnly, setLeavesOnly] = useState(false);
  const [data, setData] = useState({});
  const { sdgGoalTarget } = useSelector(
    (state) => state.countryRisk.countryStatistics
  );
  const iconList = [
    "#e5253b",
    "#dea83b",
    "#4d9f39",
    "#c51a2d",
    "#ff3921",
    "#26bde2",
    "#fcc30c",
    "#a21842",
    "#fc6925",
    "#dd1367",
    "#fe9d25",
    "#be8b2f",
    "#3f7e44",
    "#0e97d9",
    "#56c12a",
    "#00679d",
    "#18486a",
  ];
  // const loading = false;
  const chartTitle = "SDG Goal and Target";
  const titleInfoText =
    "This bubble chart contains the top 10 SDG Goal and its target value";

  const [bubbleType, setBubbleType] = useState("Goal & Target");

  useEffect(() => {
    if (sdgGoalTarget?.target) {
      const dataNew = { name: "total", children: [] };
      Object.entries(sdgGoalTarget.target).map((item, idx) => {
        let children = item[1].map((i) => {
          i["color"] = iconList[idx];
          return i;
        });
        const tempObj = {
          name: item[0],
          children: children,
          // color: iconList[idx],
        };
        dataNew.children.push(tempObj);
      });

      setData(dataNew);
    }
  }, [sdgGoalTarget]);
  const handleChange = (event, newAlignment) => {
    if (newAlignment === null) {
      return;
    }
    setBubbleType(newAlignment);
    setLeavesOnly(() => {
      return newAlignment === "Target" ? true : false;
    });
  };

  return (
    <CustomCard
      titleText={chartTitle}
      titleInfoText={titleInfoText}
      loading={loading}
      noData={data?.children?.length === 0}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "block",
          position: "relative",
        }}
      >
        <Box
          sx={{
            // background: "red",
            position: "absolute",
            top: 0,
            right: 0,
            height: "auto",
            width: "auto",
            zIndex: 9999999999,
          }}
        >
          <ToggleButtonGroup
            value={bubbleType}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
            sx={{
              height: "30px",
              width: "auto",
              "&>.Mui-selected": {
                background: theme.palette.primary.light,
              },
            }}
          >
            <ToggleButton value="Goal & Target">Goal & Target</ToggleButton>
            <ToggleButton value="Target">Target</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        {!objectIsEmpty(data) && (
          <ResponsiveCirclePacking
            data={data}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            id="name"
            value="count"
            valueFormat=">-,"
            colors={
              bubbleType === "Target"
                ? { datum: "data.color" }
                : [theme.palette.grey[200], "#003f5c", "#bc5090"]
            }
            childColor={{
              from: "color",
              modifiers: [["brighter", 0.4]],
            }}
            padding={2}
            enableLabels={true}
            leavesOnly={leavesOnly}
            labelsFilter={(label) => {
              let depth = leavesOnly ? 2 : 1;
              return label.node.depth === depth;
            }}
            labelTextColor="#fff"
            label="value"
            zoomedId={zoomedId}
            onClick={(node) => {
              setZoomedId(zoomedId === node.id ? null : node.id);
            }}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.5]],
            }}
          />
        )}
      </Box>
    </CustomCard>
  );
};
export default SDGBubbleChartNivo;
SDGBubbleChartNivo.propTypes = {
  // data: PropTypes.object,
  loading: PropTypes.bool,
};
