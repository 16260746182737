/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";

import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

//assets
import { useTranslation } from "react-i18next";
import { numFormatter } from "utils/helper";

const PopulationGrowthChart = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [series, setSeries] = useState([]);
  const [option, setOption] = useState({});
  const { language } = useSelector((state) => state.customization);

  let yAxisName = t("populationYAxisNameI18n");
  const { population } = useSelector(
    (state) => state.countryRisk.countryStatistics
  );

  useEffect(() => {
    if (population) {
      let seriesData = Object.values(population);
      setSeries([
        {
          name: yAxisName,
          data: seriesData,
        },
      ]),
        setOption({
          chart: {
            toolbar: {
              show: false,
            },
            sparkline: {
              enabled: true,
            },
          },
          // title: {
          //   text: `${yAxisName} = ${numFormatter(
          //     seriesData[seriesData.length - 1],
          //     language
          //   )}`,
          // },
          colors: [theme.palette.chart[800]],
          stroke: {
            width: 2.5,
            curve: "straight",
          },
          xaxis: {
            categories: Object.keys(population),
          },
          yaxis: {
            labels: {
              show: false,
              formatter: function (val) {
                return numFormatter(val, language);
              },
            },
          },
        });
    }
  }, [population, yAxisName, language]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {option && series.length !== 0 && (
        <Chart
          options={option}
          series={series}
          type="area"
          height="100%"
          width="100%"
        />
      )}
    </Box>
  );
};

export default PopulationGrowthChart;
