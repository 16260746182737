import config from "config";

export const logout = () => {
  localStorage.removeItem(config.JWTAccessToken);
  localStorage.removeItem(config.JWTRefreshToken);
};

export const getBrowserStorageAccessToken = () => {
  const accessToken = localStorage.getItem(config.JWTAccessToken);
  return accessToken ? accessToken : "";
};
export const getBrowserStorageRefreshToken = () => {
  const refreshToken = localStorage.getItem(config.JWTRefreshToken);
  return refreshToken ? refreshToken : "";
};
