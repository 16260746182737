import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// material-ui
import useTheme from "@mui/material/styles/useTheme";
// import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";

// project imports
import LogoSection from "../LogoSection";
import ProfileSection from "./ProfileSection";

// assets
import { IconMenu2 } from "@tabler/icons";
// import TranslationSection from "./TranslationSection";
import Logo from "ui-component/Logo";
import config from "config";
// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        zIndex: "inherit",
        width: "100%",
        height: "50px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: "rgba(17, 17, 26, 0.1) 0px 1px 0px",
        borderBottom: "2px solid " + theme.palette.secondary.dark,
        backgroundColor: theme.palette.grey[900],
      }}
    >
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 500,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
          // py: 2,
          px: 3,
        }}
      >
        <ButtonBase
          sx={{
            borderRadius: "12px",
            overflow: "hidden",
          }}
          onClick={handleLeftDrawerToggle}
        >
          <IconMenu2 stroke={2} size="1.5rem" color={theme.palette.grey[50]} />
        </ButtonBase>
        <Box
          component="span"
          sx={{
            width: "70%",
            pl: 3.5,
            display: { xs: "none", md: "block" },
            flexGrow: 1,
          }}
        >
          <LogoSection color={theme.palette.grey[50]} />
        </Box>
        <Box
          sx={{
            width: "60%",
            pl: 3.5,
            // height: "100%",
            display: { xs: "block", md: "none" },
            flexGrow: 1,
          }}
        >
          <ButtonBase
            disableRipple
            component={Link}
            to={config.defaultPath}
            sx={{ heigth: "100%", width: "100%" }}
          >
            <Logo color={theme.palette.grey[50]} />
          </ButtonBase>
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {/* <TranslationSection iconColor={theme.palette.grey[50]} /> */}
        <ProfileSection />
      </Box>
    </Box>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
