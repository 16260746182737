/* eslint-disable no-debugger */
import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./action";
import * as api from "./api";
import * as helperActionCreators from "redux/serviceAndRequest/actionCreators";

function* handleUpdateUserInformation(action) {
  try {
    yield put(
      helperActionCreators.requestStarted({
        moduleName: actionTypes.BASE_USER_ACTION_NAME,
        requestName: actionTypes.UPDATE_USER_INFORMATION,
      })
    );
    yield call(api.updateUserInformation, action.payload);
    yield put(
      helperActionCreators.requestFinished({
        moduleName: actionTypes.BASE_USER_ACTION_NAME,
        requestName: actionTypes.UPDATE_USER_INFORMATION,
      })
    );
    yield put(
      helperActionCreators.setNotificationMessage({
        infoMessage: "Successfully Updated User Information",
        infoSeverity: "success",
      })
    );
    // updatating and fetching user information
    yield put({ type: actionTypes.GET_USER_INFORMATION, token: action.token });
  } catch (error) {
    yield put(
      helperActionCreators.requestFailed({
        moduleName: actionTypes.BASE_USER_ACTION_NAME,
        requestName: actionTypes.UPDATE_USER_INFORMATION,
        error: error,
      })
    );
  }
}

function* handleGetUserInformation(action) {
  try {
    yield put(
      helperActionCreators.requestStarted({
        moduleName: actionTypes.BASE_USER_ACTION_NAME,
        requestName: actionTypes.GET_USER_INFORMATION,
      })
    );
    let userInfo = yield call(api.getUserInformation, { token: action.token });
    yield put({
      type: actionTypes.SET_USER_INFORMATION,
      payload: {
        email: userInfo.email,
        emailVerified: userInfo.email_verified,
        firstName: userInfo.given_name,
        lastName: userInfo.family_name,
        phoneNumber: userInfo?.phone_number ? userInfo?.phone_number : "",
        companyName: userInfo?.company_name ? userInfo.company_name : "",
      },
    });
    yield put(
      helperActionCreators.requestFinished({
        moduleName: actionTypes.BASE_USER_ACTION_NAME,
        requestName: actionTypes.GET_USER_INFORMATION,
      })
    );
  } catch (error) {
    yield put(
      helperActionCreators.requestFailed({
        moduleName: actionTypes.BASE_USER_ACTION_NAME,
        requestName: actionTypes.GET_USER_INFORMATION,
        error: error,
      })
    );
  }
}

function* handleRestPassword(action) {
  try {
    yield put(
      helperActionCreators.requestStarted({
        moduleName: actionTypes.BASE_USER_ACTION_NAME,
        requestName: actionTypes.RESET_USER_PASSWORD,
      })
    );
    yield call(api.resetUserPassword, { email: action.email });
    yield put(
      helperActionCreators.requestFinished({
        moduleName: actionTypes.BASE_USER_ACTION_NAME,
        requestName: actionTypes.RESET_USER_PASSWORD,
      })
    );
    yield put(
      helperActionCreators.setNotificationMessage({
        infoMessage: "Reset Link sent to your registerd Email!",
        infoSeverity: "success",
      })
    );
  } catch (error) {
    yield put(
      helperActionCreators.requestFailed({
        moduleName: actionTypes.BASE_USER_ACTION_NAME,
        requestName: actionTypes.RESET_USER_PASSWORD,
        error: error,
        severity: "success",
      })
    );
  }
}
function* authenticationSaga() {
  yield takeLatest(actionTypes.GET_USER_INFORMATION, handleGetUserInformation);
  yield takeLatest(
    actionTypes.UPDATE_USER_INFORMATION,
    handleUpdateUserInformation
  );
  yield takeLatest(actionTypes.RESET_USER_PASSWORD, handleRestPassword);
}
export default authenticationSaga;
