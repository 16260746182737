export const translationsEn = {
  higherTheBetter: "the higher the better",
  yearI18n: "Year",

  // population
  populationChartTitleI18n: "Population",
  populationYAxisNameI18n: "Population Count",
  populationInfoTextI18n:
    "Total population is based on the de facto definition of population, which counts all residents regardless of legal status or citizenship. The values shown are midyear estimates.",

  // GDP per capita
  GDPChartTitleI18n: "GDP per capita",
  GDPYAxisNameI18n: "GDP",
  GDPInfoTextI18n:
    "GDP per capita is gross domestic product divided by midyear population. GDP is the sum of gross value added by all resident producers in the economy plus any product taxes and minus any subsidies not included in the value of the products. It is calculated without making deductions for depreciation of fabricated assets or for depletion and degradation of natural resources. Data are in current U.S. dollars.",

  // co2 emission
  co2EmissionChartTitleI18n: "CO₂ Emission",
  co2EmissionYAxisNameI18n: "Emission",
  co2EmissionInfoTextI18n:
    "Carbon dioxide emissions are those stemming from the burning of fossil fuels and the manufacture of cement. They include carbon dioxide produced during consumption of solid, liquid, and gas fuels and gas flaring.",

  // air pollution
  childrenEmploymentChartTitleI18n: "Children Employment",
  childrenEmploymentYAxisNameI18n: "Employment Percentage",
  childrenEmploymentInfoTextI18n:
    "Children in employment refer to children involved in economic activity for at least one hour in the reference week of the survey.",

  // air pollution
  airPollutionChartTitleI18n: "Air Pollution",
  airPollutionYAxisNameI18n: "PM2.5 mean annual exposure",
  airPollutionSafetyLimitI18n: "Safety limit 10 μg/m³",
  airPollutionInfoTextI18n:
    "Population-weighted exposure to ambient PM2.5 pollution is defined as the average level of exposure of a nation's population to concentrations of suspended particles measuring less than 2.5 microns in aerodynamic diameter, which are capable of penetrating deep into the respiratory tract and causing severe health damage. Exposure is calculated by weighting mean annual concentrations of PM2.5 by population in both urban and rural areas.",

  //sentiment
  sentimentChartTitleI18n: "Overall Risk",
  sentimentChartInfoI18n:
    "The following Chart shows the overall Risk of the selected country",

  //   human rights
  humanRightsChartTitleI18n: "Sector wise Human Rights Violation",
  humanRightsChartXAxisNameI18n: "Sector Name",
  humanRightsChartYAxisNameI18n: "HR violation Count (the lower the better)",
  humanRightsChartInfoI18n:
    "The following chart shows the Sector wise Human Rights violation count.",

  // Risk category
  riskCategoryChartTitleI18n: "Risk Category vs Score",
  riskCategoryChartXAxisNameI18n: "Risk Category",
  riskCategoryChartYAxisNameI18n: "Risk Score (the lower the better)",
  riskCategoryChartInfoI18n:
    "The below chart describes various Risk categories and their score",

  // freedom
  freedomChartTitleI18n: "Freedom Comparison",
  freedomChartOverallScoreI18n: "Overall Score",
  freedomChartPoliticalRightsI18n: "Political Rights",
  freedomChartCivilLibertiesI18n: "Civil Liberties",
  freedomChartInfoI18n:
    "The below chart compares freedom score of the selected country with the mean freedom score in various organisatins & Associations such as BRICS, ASEAN, G20 countries and European Union",

  //corruption
  corruptionChartTitleI18n: "Corruption comparision",
  corruptionChartYAxisLeftNameI18n: "Score (the higher the better)",
  corruptionChartYAxisRightNameI18n: "Rank (the lower the better)",
  corruptionChartInfoI18n:
    "The below chart compares corruption score of the selected country against the Mean Corruption Score of various organisatins & Associations such as BRICS, ASEAN, G20 countries and European Union",

  // SDG Goal
  SDGGoalChartTitleI18n: "SDG Goal Recommendations",
  SDGGoalChartXAxisNameI18n: "SDG Goal",
  SDGGoalChartYAxisNameI18n: "Recommendations Count (the lower the better)",
  SDGGoalChartInfoI18n:
    "The below chart describes the selected country's top 10 SDG Goal and their recommendation count.",

  // SDG Target
  SDGTargetChartTitleI18n: "SDG Target Recommendations",
  SDGTargetChartXAxisNameI18n: "SDG Target",
  SDGTargetChartYAxisNameI18n: "Recommendations Count (the lower the better)",
  SDGTargetChartInfo1I18n: `SDG Targets are the subdivision of SDG Goals.`,
  SDGTargetChartInfo2I18n:
    "The below chart describes the selected country's SDG Target and their recommendation count.",

  //   NGO sector wise actions count
  NGOTopSectorChartTitleI18n: "NGO Action Based on Issue",
  NGOTopSectorChartXAxisNameI18n: "Action Name",
  NGOTopSectorChartYAxisNameI18n: "Action Count (the lower the better)",
  NGOTopSectorChartInfoI18n:
    "The following chart shows the concentration of the NGO actions in various sectors of the selected country , the top 10 sectors are listed below",

  //   NGO top actions count
  NGOActionCountChartTitleI18n: "NGO Action Count",
  NGOActionCountChartXAxisNameI18n: "Action Name",
  NGOActionCountChartYAxisNameI18n: "Action Count (the lower the better)",
  NGOActionCountChartInfoI18n:
    "The following chart shows the total count of the NGO actions in the selected country from 2016 to 2021.",
};

export const translationsJp = {
  higherTheBetter: "高いほど良い",
  yearI18n: "年",

  // population
  populationChartTitleI18n: "人口",
  populationYAxisNameI18n: "人口数",
  populationInfoTextI18n:
    "総人口は、人口の事実上の定義に基づいており、法的地位や市民権に関係なく、すべての居住者を数えます。 表示されている値は年央の推定値です。",

  // GDP per capita
  GDPChartTitleI18n: "一人当たりGDP",
  GDPYAxisNameI18n: "GDP",
  GDPInfoTextI18n:
    "一人当たり GDP は、国内総生産を年央人口で割ったものです。 GDP は、経済内のすべての居住生産者によって付加された総付加価値の合計に、製品税を加え、製品の価値に含まれていない補助金を差し引いたものです。 これは、加工資産の減価償却や天然資源の枯渇や劣化を控除せずに計算されます。 データは現在の米ドルです。",

  // co2 emission
  co2EmissionChartTitleI18n: "二酸化炭素排出量",
  co2EmissionYAxisNameI18n: "放出",
  co2EmissionInfoTextI18n:
    "二酸化炭素の排出は、化石燃料の燃焼とセメントの製造に起因するものです。 それらには、固体、液体、およびガス燃料の消費およびガスのフレアリング中に生成される二酸化炭素が含まれます。",

  // air pollution
  childrenEmploymentChartTitleI18n: "子供の雇用",
  childrenEmploymentYAxisNameI18n: "雇用率",
  childrenEmploymentInfoTextI18n:
    "就労中の子どもとは、調査対象週に 1 時間以上経済活動に従事している子どもを指します。",

  // air pollution
  airPollutionChartTitleI18n: "大気汚染",
  airPollutionYAxisNameI18n: "PM2.5 平均年間曝露量",
  airPollutionSafetyLimitI18n: "安全限界 10 μg/m³",
  airPollutionInfoTextI18n:
    "大気中の PM2.5 汚染への人口加重暴露は、空気力学的直径が 2.5 ミクロン未満の浮遊粒子の濃度への国家人口の暴露の平均レベルとして定義されます。この粒子は気道の奥深くまで浸透し、深刻な健康を引き起こす可能性があります。 ダメージ。 ばく露量は、PM2.5 の年間平均濃度を都市部と農村部の両方の人口で重み付けすることによって計算されます。",

  //sentiment
  sentimentChartTitleI18n: "全体的なリスク",
  sentimentChartInfoI18n:
    "次のグラフは、選択した国の全体的なリスクを示しています。",

  //   human rights
  humanRightsChartTitleI18n: "セクターごとの人権侵害",
  humanRightsChartXAxisNameI18n: "セクター名",
  humanRightsChartYAxisNameI18n: "人権侵害カウント (低いほど良い)",
  humanRightsChartInfoI18n:
    "次のグラフは、セクター別の人権侵害件数を示しています。",

  // Risk category
  riskCategoryChartTitleI18n: "リスク カテゴリとスコア",
  riskCategoryChartXAxisNameI18n: "リスクカテゴリ",
  riskCategoryChartYAxisNameI18n: "リスク スコア (低いほど良い)",
  riskCategoryChartInfoI18n:
    "以下のチャートは、さまざまなリスク カテゴリとそのスコアを示しています。",

  // freedom
  freedomChartTitleI18n: "自由度比較",
  freedomChartOverallScoreI18n: "総合評点",
  freedomChartPoliticalRightsI18n: "政治的権利",
  freedomChartCivilLibertiesI18n: "市民の自由",
  freedomChartInfoI18n:
    "下のグラフは、選択した国の自由度スコアと、BRICS、ASEAN、G20 諸国、欧州連合などのさまざまな組織や協会の自由度の平均値を比較したものです。",

  //corruption
  corruptionChartTitleI18n: "腐敗の比較",
  corruptionChartYAxisLeftNameI18n: "スコア（低いほど良い）",
  corruptionChartYAxisRightNameI18n: "ランク（低いほど良い）",
  corruptionChartInfoI18n:
    "下のグラフは、選択した国の汚職スコアを、BRICS、ASEAN、G20 諸国、欧州連合などのさまざまな組織や協会の平均汚職スコアと比較しています。",

  // SDG Goal
  SDGGoalChartTitleI18n: "SDG ゴールの推奨事項",
  SDGGoalChartXAxisNameI18n: "SDG ゴール",
  SDGGoalChartYAxisNameI18n: "推奨数 (低いほど良い)",
  SDGGoalChartInfoI18n:
    "以下のグラフは、選択された国の上位 10 の SDG ゴールとその推奨数を示しています。",

  // SDG Target
  SDGTargetChartTitleI18n: "SDG ターゲットの推奨事項",
  SDGTargetChartXAxisNameI18n: "SDG ターゲット",
  SDGTargetChartYAxisNameI18n: "推奨数 (低いほど良い)",
  SDGTargetChartInfo1I18n: "SDGターゲットは、SDGゴールのサブディビジョンです。",
  SDGTargetChartInfo2I18n:
    "以下のグラフは、選択された国の上位 10 の SDG ターゲッとその推奨数を示しています。",

  //   NGO sector wise actions
  NGOTopSectorChartTitleI18n: "問題に基づくNGOの活動",
  NGOTopSectorChartXAxisNameI18n: "アクション名",
  NGOTopSectorChartYAxisNameI18n: "アクション数 (低いほど良い)",
  NGOTopSectorChartInfoI18n:
    "次のグラフは、選択した国のさまざまなセクターにおける NGO の活動の集中を示しています。上位 10 のセクターを以下に示します",

  //   NGO top actions count
  NGOActionCountChartTitleI18n: "NGOアクションカウント",
  NGOActionCountChartXAxisNameI18n: "アクション名",
  NGOActionCountChartYAxisNameI18n: "アクション数 (低いほど良い)",
  NGOActionCountChartInfoI18n:
    "次のグラフは、2016 年から 2021 年までに選択した国で行われた NGOアクションの総数を示しています。",
};
