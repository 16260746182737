import { hrCategoryNameList } from "utils/constants";
import * as actionTypes from "./action";

export const initialState = {
  search: {
    selectedCountries: [],
    category: [...Object.keys(hrCategoryNameList)],
    sector: [],
    dateRangeOption: "all",
    fromDateRange: "",
    toDateRange: "",
  },
  countryDetail: [],
  // countryIndicatorDetail: {},
  countryIndicatorData: {},
  // populationIndicatorDetail: {},
  populationIndicatorData: {},
  supplyChain: {},
  supplyChainWithIssueCategory: {},
  humanRights: {},
  filteredNGORecords: [],
};

const CountriesComparisionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_COUNTRIES_STATISTICS:
      return {
        ...state,
        countryDetail: action.payload.country_detail,
        // countryIndicatorDetail: action.payload.country_indicators_detail,
        countryIndicatorData: action.payload.country_indicators_data,
        // populationIndicatorDetail: action.payload.population_indicators_detail,
        populationIndicatorData: action.payload.population_indicators_data,
        supplyChain: action.payload.supply_chain_risk,
        humanRights: action.payload.human_rights_risk,
        supplyChainWithIssueCategory:
          action.payload.supply_chain_issue_category_data,
      };
    case actionTypes.SET_SELECTED_COUNTRIES:
      return {
        ...state,
        search: {
          selectedCountries: action.payload.searchedCountryList,
          dateRangeOption: action.payload.dateRangeOption,
          category: action.payload.category,
          sector: action.payload.sector,
          fromDateRange: action.payload.fromDateRange,
          toDateRange: action.payload.toDateRange,
        },
        countryIndicatorData: {},
        populationIndicatorData: {},
        supplyChain: {},
        supplyChainWithIssueCategory: {},
        humanRights: {},
        filteredNGORecords: [],
      };
    case actionTypes.CLEAR_COUNTRIES_STATISCS:
      return {
        ...initialState,
      };
    case actionTypes.SET_FILTERED_NGO_RECORDS:
      return {
        ...state,
        filteredNGORecords: [...action.payload],
      };
    default:
      return state;
  }
};
export default CountriesComparisionReducer;
