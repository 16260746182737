import { axiosJWT } from "common/axiosPrivate";
const endUrl = "/";
export const getWorldMapJson = async () => {
  try {
    let response = await axiosJWT("esg/world-map-geo-json" + endUrl);
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};
export const getCountryRiskCategoryList = async () => {
  try {
    let response = await axiosJWT("esg/country-risk-category-list" + endUrl);
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};
export const getCountryList = async () => {
  try {
    let response = await axiosJWT("esg/country-list" + endUrl);
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};
export const getCountryStatistics = async (countryCode) => {
  try {
    let response = await axiosJWT(
      "esg/country-statistics/?country_code=" + countryCode
    );
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};

export const getCountryRiskSentiment = async (countryCode) => {
  try {
    let response = await axiosJWT(
      "esg/country-sentiment/?country_code=" + countryCode
    );
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};
export const getFreedomAssociationAverage = async () => {
  try {
    let response = await axiosJWT("esg/freedom-average" + endUrl);
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};

export const getCorruptionAssociationAverage = async () => {
  try {
    let response = await axiosJWT("esg/corruption-average" + endUrl);
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};
export const getIndicatorData = async (countryCode) => {
  try {
    let response = await axiosJWT(
      "esg/country-risk-indicators?country_code=" + countryCode
    );
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};
export const getSDGGoalTarget = async (countryCode) => {
  try {
    let response = await axiosJWT(
      "esg/sdg-goal-target/?country_code=" + countryCode
    );
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};
export const getNGOTopActivistAction = async (countryCode) => {
  try {
    let response = await axiosJWT(
      "esg/country-ngo-top-action/?country_code=" + countryCode
    );
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};
export const getNGOActionCount = async (countryCode) => {
  try {
    let response = await axiosJWT(
      "esg/country-ngo-action-count/?country_code=" + countryCode
    );
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};

export const getMostTargetCompaniesByNGOs = async (countryCode) => {
  try {
    let response = await axiosJWT(
      "esg/top-targated-corporates/?country_code=" + countryCode
    );
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};
