import { axiosJWT } from "common/axiosPrivate";
import { axiosPublic } from "common/axiosPublic";

export const login = async (username, password) => {
  try {
    let response = await axiosPublic.post("/api/token/", {
      username,
      password,
    });
    return response.data;
  } catch (e) {
    throw "usernamePasswordInvalidI18n";
  }
};

export const getRefreshedAccessToken = async (refreshToken) => {
  try {
    let response = await axiosPublic.post("api/token/refresh/", {
      refresh: refreshToken,
    });
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};

export const logoutRefreshToken = async (refreshToken) => {
  try {
    await axiosPublic.post("/api/token/logout/", {
      refresh: refreshToken,
    });
    return { message: "Logout Successful" };
  } catch (e) {
    throw "API thew error";
  }
};
export const getUserInformation = async (payload) => {
  try {
    let response = await axiosJWT.post("/api/get-user-info/", payload);
    return response.data;
  } catch (e) {
    throw "Unable to retrieve user information Unsuccessfull";
  }
};
export const updateUserInformation = async (payload) => {
  try {
    await axiosJWT.post("/api/update-user-info/", payload);
    return { message: "Successfully Updated User Information" };
  } catch (e) {
    throw "Update Unsuccessfull";
  }
};
export const resetUserPassword = async (payload) => {
  try {
    await axiosJWT.put("/api/send-reset-password-email/", payload);
    return { message: "Reset Link sent to your Email!" };
  } catch (e) {
    throw "Reset Link sent to your Email!";
  }
};
