import React from "react";
import PropTypes from "prop-types";
// mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import useTheme from "@mui/material/styles/useTheme";
import Grid from "@mui/material/Grid";
import Popper from "@mui/material/Popper";
// assets
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";

const WorldMapLegends = (props) => {
  const { legendList, countryRiskCategoryList, countryRiskCategory } = props;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleLegendOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLegendClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{ color: theme.palette.grey[900] }}
      >
        <a
          style={{ color: "inherit" }}
          target="_blank"
          rel="noopener noreferrer"
          href={
            countryRiskCategoryList.filter(
              (item) => item.key === countryRiskCategory
            )[0]?.link
          }
        >
          <Typography
            variant="h5"
            sx={{ color: "inherit", fontWeight: 700, fontStyle: "italic" }}
          >
            {`Data Source : 
            ${
              countryRiskCategoryList.filter(
                (item) => item.key === countryRiskCategory
              )[0]?.source
            } `}
          </Typography>
        </a>
      </Stack>
      <Box
        sx={{
          height: "40px",
          width: "40px",
          backgroundColor: theme.palette.secondary.main,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "4px",
          border: "3px solid" + theme.palette.grey[900],
          cursor: "pointer",
        }}
        onMouseEnter={handleLegendOpen}
        onMouseLeave={handleLegendClose}
      >
        <LayersOutlinedIcon
          fontSize="medium"
          sx={{ color: theme.palette.grey[900] }}
        />
        <Popper id={id} open={open} anchorEl={anchorEl} placement={"top-end"}>
          <Box
            sx={{
              mb: 3,
              height: "250px",
              width: "150px",
              bgcolor: theme.palette.background.paper,
              border: "1px solid " + theme.palette.grey[400],
              borderRadius: "4px",
              p: 1,
            }}
          >
            <Stack direction="column" spacing={2}>
              <Typography variant="h5">{"LEGENDS"}</Typography>

              {legendList.map((item, idx) => {
                return (
                  <Stack key={idx} direction="row" spacing={1}>
                    <Box
                      sx={{
                        height: "20px",
                        width: "20px",
                        bgcolor: item.color,
                        borderRadius: "4px",
                        border: " 1px solid " + theme.palette.grey[200],
                      }}
                    />
                    <Typography
                      variant="h5"
                      sx={{ color: theme.palette.text.primary }}
                    >
                      {item.name}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
          </Box>
        </Popper>
      </Box>
    </Grid>
  );
};

export default WorldMapLegends;

WorldMapLegends.propTypes = {
  legendList: PropTypes.array,
  countryRiskCategoryList: PropTypes.array,
  countryRiskCategory: PropTypes.string,
};
