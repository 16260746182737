import { all, fork } from "redux-saga/effects";
import companyRiskSaga from "./companyRisk/saga";
import countriesComparisionSaga from "./compareCountries/saga";
import countryRiskSaga from "./countryRisk/saga";
import authenticationSaga from "./authentication/saga";

export default function* rootSaga() {
  // yield all(countryRiskSaga());
  yield all([
    fork(countryRiskSaga),
    fork(countriesComparisionSaga),
    fork(companyRiskSaga),
    fork(authenticationSaga),
  ]);
}
