import * as actionTypes from "./action";

export const initialState = {
  search: {
    searchType: "entity",
    companySearchText: "",
    japaneseSearch: false,
    categoryList: [],
    sectorList: [],
    country: null,
    dateRangeOption: "custom",
    fromDateRange: "",
    toDateRange: "",
    enableClear: false,
    searched: false,
    autoCompleteList: [],
  },
  NGOReport: {
    actionFrequency: [],
    actionFrequencyMovingAverage: [],
    articleCount: null,
    articleList: [],
    hrIssueCategory: {},
    sentiment: {},
    volume: {},
    wordFrequency: [],
  },
  news: {
    articleCount: null,
    articleList: [],
    hrIssueCategory: {},
    sentiment: {},
    volume: {},
    wordFrequency: [],
  },
  sanctions: {
    csl: [],
  },
  detail: {},
};

const CompanyRiskReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NGO_REPORT_DATA:
      return {
        ...state,
        NGOReport: {
          articleCount: action.payload.articleCount,
          articleList: action.payload.articleList,
          hrIssueCategory: action.payload.hrIssueCategory,
          sentiment: action.payload.sentiment,
          volume: action.payload.volume,
          wordFrequency: action.payload.wordFrequency,
          actionFrequency: action.payload.actionFrequency,
          actionFrequencyMovingAverage:
            action.payload.actionFrequencyMovingAverage,
        },
      };
    case actionTypes.RESET_NGO_REPORT_DATA:
      return {
        ...state,
        NGOReport: {
          actionFrequency: [],
          actionFrequencyMovingAverage: [],
          articleCount: null,
          articleList: [],
          hrIssueCategory: {},
          sentiment: {},
          volume: {},
          wordFrequency: [],
        },
      };

    case actionTypes.SET_COMPANY_NEWS:
      return {
        ...state,
        news: {
          articleCount: action.payload.articleCount,
          articleList: action.payload.articleList,
          volume: action.payload.volume,
          sentiment: action.payload.sentiment,
          hrIssueCategory: action.payload.hrIssueCategory,
          wordFrequency: action.payload.wordFrequency,
        },
      };
    case actionTypes.SET_COMPANY_CSL_SANCTION:
      return {
        ...state,
        sanctions: {
          ...state.sanctions,
          csl: [...action.payload.data],
        },
      };
    case actionTypes.SET_COMPANY_SEARCH_FIELD:
      return {
        ...state,
        search: {
          ...action.payload,
          autoCompleteList: state.search.autoCompleteList,
          enableClear: true,
          searched: true,
        },
      };
    case actionTypes.SET_COMPANY_DETAIL:
      return {
        ...state,
        detail: action.payload,
      };
    case actionTypes.CLEAR_COMPANY_INFO:
      return {
        ...initialState,
      };
    case actionTypes.SET_COMPANY_AUTOCOMPLETE:
      return {
        ...state,
        search: {
          ...state.search,
          autoCompleteList: action.payload.autocompletes,
        },
      };

    default:
      return state;
  }
};

export default CompanyRiskReducer;
