import { createSelector } from "reselect";

const requestState = (state) => state.serviceAndRequest;

export const requestsInProgress = (state) =>
  requestState(state).requests.filter((request) => request.inProgress).length >
  0;

//get requests in progress either by single requestName or by requestNames array
export const namedRequestsInProgress = createSelector(
  (state) => requestState(state),
  (_, args) => args,
  (requestHelper, args) => {
    const { moduleName, requestName } = args;
    let result = requestHelper.requests[moduleName]?.find((request) => {
      return request.requestName === requestName && request.inProgress === true;
    });
    return result != undefined;
  }
);

export const namedRequestError = (args) => {
  const { state, moduleName, requestName } = args;
  const errorObj = requestState(state).requests[moduleName].find(
    (request) =>
      request.requestName === requestName && request.inProgress == false
  );
  return errorObj === undefined ? false : true;
};
