import { PropTypes } from "prop-types";
import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";

export const TitleTypography = (props) => {
  const { title } = props;
  const theme = useTheme();
  return (
    <Typography
      variant={"h4"}
      sx={{
        fontWeight: 700,
        color: theme.palette.primary[800],
        fontStyle: "italic",
      }}
    >
      {title}
    </Typography>
  );
};
TitleTypography.propTypes = {
  title: PropTypes.string.isRequired,
};
