// project imports
import config from "config";

// action - state management
import * as actionTypes from "./actions";

export const initialState = {
  selectedDrawerMenuItem: "", // for active default menu
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  sideDrawerOpened: false,
  language: config.defaultLanguage,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //
// limitations of react reducer function
// - It should be synchronous
// - it should not mutate the original state
const customizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_SIDE_MENU:
      return {
        ...state,
        selectedDrawerMenuItem: action.id,
      };
    case actionTypes.SET_SIDE_DRAWER_STATE:
      return {
        ...state,
        sideDrawerOpened: action.sideDrawerOpened,
      };
    case actionTypes.SET_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };
    case actionTypes.SET_FONT_FAMILY:
      return {
        ...state,
        fontFamily: action.fontFamily,
      };
    case actionTypes.SET_BORDER_RADIUS:
      return {
        ...state,
        borderRadius: action.borderRadius,
      };
    default:
      return state;
  }
};

export default customizationReducer;
