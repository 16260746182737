import config from "config";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import {
  translationsEn as countryRiskEn,
  translationsJp as countryRiskJp,
} from "./countryRisk";
import {
  translationsEn as companyRishEn,
  translationsJp as companyRiskJp,
} from "./companyRisk";

const translationsEn = {
  loading: "Loading...",
  search: "Search",
  company_name: "Company Name",
  company_name_placeholder: "Please Enter Company Name",
  country_name: "Country Name",
  country_name_placeholder: "Please Enter Country Name",
  country_search: "Company Search",
  esg_report: "Esg Report",
  dashboard: "Dashboard",
  account_settings: "Account Settings",
  logout: "Logout",
  new_registeration: "New Registeration",
  sign_up: "Sign Up",
  sign_up_text: "Don't have an account?",
  user_name_label: "Email Address / Username",
  password_label: "Password",
  forgot_password: "Forgot Password?",
  log_in: "Log In",
  sign_in_text: "Already have an account?",
  remember_me: "Remember me",
  first_name: "First Name",
  last_name: "Last Name",
  or_text: "or",
  poor: "Poor",
  weak: "Weak",
  normal: "Normal",
  good: "Good",
  strong: "Strong",
  invalid_email_error_text: "Must be a valid email",
  email_required_error_text: "Email is required",
  password_required_error_text: "Password is required",
  filterI18n: "Filter",
  typeI18n: "Type",
  isI18n: "is",
  usernamePasswordInvalidI18n: "Invalid Username / Password",
  ...countryRiskEn,
  ...companyRishEn,
};
const translationsJp = {
  loading: "読み込み中...",
  search: "検索",
  company_name: "会社名",
  company_name_placeholder: "会社名を入力してください",
  country_name: "国名",
  country_name_placeholder: "国を入力してください",
  country_search: "会社検索",
  esg_report: "ESG レポート",
  dashboard: "ダッシュボード",
  account_settings: "アカウント設定",
  logout: "ログアウト",
  new_registeration: "新規登録",
  sign_up: "サインアップ",
  sign_up_text: "サインアップ",
  user_name_label: "メールアドレス/ユーザー名",
  password_label: "パスワード",
  forgot_password: "パスワードを再設定する",
  log_in: "ログイン",
  sign_in_text: "ログイン",
  remember_me: "私を覚えて",
  first_name: "名前",
  last_name: "名字",
  or_text: "又は",
  poor: "貧弱",
  weak: "弱い",
  normal: "通常",
  good: "良い",
  strong: "強力",
  invalid_email_error_text: "有効なメールアドレスである必要があります",
  email_required_error_text: "メールアドレスが必要です",
  password_required_error_text: "パスワードが必要です",
  filterI18n: "検索",
  typeI18n: "タイプ",
  isI18n: "は",
  usernamePasswordInvalidI18n: "ユーザー名/パスワードが無効です",
  ...countryRiskJp,
  ...companyRiskJp,
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: translationsEn },
      ja: { translation: translationsJp },
    },
    lng: config.defaultLanguage, // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
