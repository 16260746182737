import { Box, Stack, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";

// assets
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
// eslint-disable-next-line no-unused-vars
import { useEffect } from "react";

const RiskLevelCellRenderer = (props) => {
  const { data, riskLevel } = props;
  const theme = useTheme();
  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      {riskLevel && (
        <Stack direction="column" spacing={0.5}>
          <Typography component="div" sx={{ fontWeight: 600, pb: 1 }}>
            {data}
          </Typography>
          <Stack direction="row" spacing={1}>
            <Typography component="div">{riskLevel}</Typography>
            {riskLevel === "Very High Risk" && (
              <WarningOutlinedIcon
                fontSize={"small"}
                sx={{ color: theme.palette.error.main }}
              />
            )}
          </Stack>
        </Stack>
      )}
      {riskLevel === null && "Not Available"}
    </Box>
  );
};

export default RiskLevelCellRenderer;
RiskLevelCellRenderer.propTypes = {
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  riskLevel: PropTypes.string,
};
