import React, { useEffect, useState } from "react";
import PropType from "prop-types";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

//assets
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

// ui components
import CustomCard from "ui-component/card";

const FreedomChart = (props) => {
  const { loading } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    freedom,
    freedomAssociationAverage,
    name: countryName,
  } = useSelector((state) => state.countryRisk.countryStatistics);
  let [series, setSeries] = useState([]);
  let [option, setOption] = useState({});
  let [category, setCategorey] = useState("score");
  let chartTitle = t("freedomChartTitleI18n");
  let xaxisName = t("yearI18n");
  let filterOptions = [
    { key: "score", value: t("freedomChartOverallScoreI18n") },
    {
      key: "political_rights",
      value: t("freedomChartPoliticalRightsI18n"),
    },
    {
      key: "civil_liberties",
      value: t("freedomChartCivilLibertiesI18n"),
    },
  ];

  useEffect(() => {
    if (freedom && freedom?.length > 0) {
      setSeries([
        {
          name: countryName,
          data: freedom.map((data) => data[category]),
          type: "area",
        },
        {
          name: "BRICS",
          data: freedomAssociationAverage.brics.map((data) => data[category]),
          type: "line",
        },
        {
          name: "European Union (EU)",
          data: freedomAssociationAverage.eu.map((data) => data[category]),
          type: "line",
        },
        {
          name: "G20",
          data: freedomAssociationAverage.g20.map((data) => data[category]),
          type: "line",
        },
        {
          name: "ASEAN",
          data: freedomAssociationAverage.asean.map((data) => data[category]),
          type: "line",
        },
      ]);
      setOption({
        chart: {
          toolbar: {
            show: true,
          },
        },
        colors: ["#ffa600", "#ff6361", "#bc5090", "#58508d", "#003f5c"],
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0],
          style: {
            colors: [theme.palette.grey[900]],
          },
          background: {
            enabled: false,
          },
          offsetY: -5,
        },
        yaxis: {
          tickAmount: 5,
          min:
            0 < Math.min(...freedom.map((data) => data[category]))
              ? 0
              : Math.min(...freedom.map((data) => data[category])),
          max: () => {
            return category === "score"
              ? 100
              : category === "political_rights"
              ? 40
              : 60;
          },
          title: {
            text:
              filterOptions.filter((opt) => opt.key == category)[0].value +
              ` (${t("higherTheBetter")})`,
          },
        },
        xaxis: {
          type: "category",
          tickPlacement: "off",
          title: {
            text: xaxisName,
          },
          categories: freedom.map((data) => {
            return data["year"];
          }),
          labels: {
            show: true,
          },
        },
        grid: {
          show: false,
          padding: {
            left: 20,
            top: 10,
          },
        },

        stroke: {
          width: [1, 3, 3, 3, 3],
          curve: ["straight", "straight", "straight", "straight", "straight"],
        },
        fill: {
          type: ["gradient", "solid", "solid", "solid", "solid"],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: [0.7, 1, 1, 1, 1],
            opacityTo: [0.4, 1, 1, 1, 1],
          },
        },
        markers: {
          size: [3, 0, 0, 0, 0],
        },
      });
    }
  }, [freedom, category, xaxisName]);
  return (
    <CustomCard
      titleText={chartTitle}
      titleInfoText={t("freedomChartInfoI18n")}
      loading={loading}
      noData={freedom?.length === 0}
    >
      {option && series.length && (
        <Chart
          options={option}
          series={series}
          type="line"
          height="87%"
          width="100%"
        />
      )}
      {/* filter */}
      <Grid
        item
        xs={12}
        container
        sx={{
          height: "13%",
          mt: 2,
          backgroundColor: theme.palette.grey[300],
          borderTop: "2px solid rgba(0,0,0,0.4)",
          position: "relative",
        }}
      >
        <Accordion
          sx={{
            position: "absolute",
            bottom: 0,
            backgroundColor: theme.palette.grey[300],
            width: "100%",
          }}
        >
          <AccordionSummary expandIcon={<ExpandLessIcon />}>
            <Typography
              variant="h4"
              sx={{ fontWeight: 700, color: theme.colors?.textDark }}
            >
              {t("filterI18n")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              item
              xs={4}
              container
              direction="column"
              sx={{ height: "100%", width: "100%" }}
            >
              {category && filterOptions.length && (
                <>
                  <Typography
                    variant="h5"
                    sx={{ color: theme.palette.text.primary, pl: 1, pb: 0.4 }}
                  >
                    {t("typeI18n")}
                  </Typography>

                  <FormControl
                    variant="outlined"
                    size="large"
                    sx={{ minWidth: 150, height: "100%" }}
                  >
                    <Select
                      sx={{ height: "30px" }}
                      value={category}
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={(d) => setCategorey(d.target.value)}
                    >
                      {filterOptions.map((opt) => {
                        return (
                          <MenuItem key={opt.key} value={opt.key}>
                            {opt.value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </CustomCard>
  );
};

export default FreedomChart;
FreedomChart.propTypes = {
  loading: PropType.bool,
};
