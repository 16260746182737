/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import * as actions from "redux/serviceAndRequest/actionCreators";

export const SnackbarNotification = ({ message, severity }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(actions.resetNotificationMessage());
    setOpen(false);
  };

  if (!message) {
    return null;
  }
  useEffect(() => {
    setOpen(true);
  }, []);
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
