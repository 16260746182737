import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import Zoom from "@mui/material/Zoom";

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    TransitionComponent={Zoom}
    // placement="right"
    arrow
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.grey[900],
    maxWidth: "40rem",
    borderRadius: "4px",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
    "& .MuiTypography-root": {
      color: theme.palette.background.paper,
      lineHeight: 1.5,
      margin: "3px",
      fontWeight: "500",
    },
  },
}));
