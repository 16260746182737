import * as actionTypes from "./action";

export const initialState = {
  worldMapGeoJson: {},
  countryRiskCategoryList: [],
  countryStatistics: {},
  countryList: [],
  selectedCountry: "",
};

const CountryRiskReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_WORLD_MAP_JSON_DATA:
      return {
        ...state,
        countryRiskCategoryList: action.countryRiskCategoryListData,
        worldMapGeoJson: action.worldMapData,
      };
    // case actionTypes.SET_INDICATOR_DATA:
    //   return {
    //     ...state,
    //     indicatorData: action.payload,
    //   };
    case actionTypes.CLEAR_WORLD_MAP_JSON_DATA:
      return {
        ...state,
        worldMapGeoJson: {},
        countryRiskcategoryList: [],
      };
    case actionTypes.SET_COUNTRY_STATISTICS:
      return {
        ...state,
        countryStatistics: action.payload,
      };
    case actionTypes.CLEAR_COUNTRY_STATISTICS:
      return {
        ...state,
        countryStatistics: initialState.countryStatistics,
      };
    case actionTypes.SET_COUNTRY_LIST:
      return {
        ...state,
        countryList: [...action.payload],
      };
    case actionTypes.SET_COUNTRY_ISO_A3:
      return {
        ...state,
        selectedCountry: action.payload,
      };
    default:
      return state;
  }
};

export default CountryRiskReducer;
