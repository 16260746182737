// material-ui
import useTheme from "@mui/material/styles/useTheme";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

// ==============================|| SKELETON - EARNING CARD ||============================== //

const WidgetCardSkeleton = () => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        height: 180,
        border: "1px solid " + theme.palette.grey[200],
        boxShadow: "0 2px 14px 0 rgb(32 40 45 / 8%)",
      }}
    >
      <CardContent sx={{ height: "100%" }}>
        <Grid
          container
          direction="column"
          alignItems={"center"}
          justifyContent={"center"}
          spacing={4}
        >
          <Grid item xs={12} sx={{ width: "100%" }}>
            {/* <Typography variant={"h1"} component={"div"} sx={{ width: "100%" }}> */}
            <Skeleton variant="rectangular" width={"100%"} height={44} />
            {/* </Typography> */}
          </Grid>
          <Grid item xs={12} sx={{ width: "100%" }}>
            {/* <Typography variant={"h4"} component={"span"} sx={{ width: "100%" }}> */}
            <Skeleton variant="rectangular" width={"100%"} height={34} />
            {/* </Typography> */}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default WidgetCardSkeleton;
