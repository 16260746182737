/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

// material-ui
import useTheme from "@mui/material/styles/useTheme";
import styled from "@mui/material/styles/styled";

import useMediaQuery from "@mui/material/useMediaQuery";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";

// project imports

import Header from "./Header";
import Sidebar from "./Sidebar";

import { drawerWidth } from "redux/customization/constant";
import { SET_SIDE_DRAWER_STATE } from "redux/customization/actions";
import { Footer } from "./Footer";

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, worldmapview }) => ({
    ...theme.typography.mainContent,
    ...(worldmapview === "false" && {
      height: "100%",
    }),
    ...(!open && {
      borderRadius: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: 50,
      }),
      [theme.breakpoints.up("md")]: {
        marginLeft: -drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down("md")]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: 50,
      }),
      marginLeft: 0,
      borderRadius: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down("md")]: {
        // marginLeft: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        // marginLeft: "10px",
      },
    }),
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const location = useLocation();

  const worldmapview = location.pathname === "/";
  // const worldmapview = location.pathname === "/risk-heatmap";
  const theme = useTheme();
  // const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));
  // Handle left drawer
  const leftDrawerOpened = useSelector(
    (state) => state.customization.sideDrawerOpened
  );
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({
      type: SET_SIDE_DRAWER_STATE,
      sideDrawerOpened: !leftDrawerOpened,
    });
  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened
            ? theme.transitions.create("width")
            : "none",
          zIndex: 998,
        }}
      >
        <Toolbar
          disableGutters={true}
          sx={{
            p: 0,
            backgroundColor: theme.palette.primaryDark,
            zIndex: "inherit",
          }}
        >
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar
        drawerOpen={leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
      />

      {/* main content */}
      <Main
        theme={theme}
        open={leftDrawerOpened}
        worldmapview={worldmapview.toString()}
      >
        <Outlet />
        {/* footer content */}
        {/* {!worldmapview && <Footer />} */}
      </Main>
    </Box>
  );
};

export default MainLayout;
