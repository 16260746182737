/* eslint-disable no-unused-vars */
import { useTheme } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import Chart from "react-apexcharts";
import PropTypes from "prop-types";
import CustomCard from "ui-component/card";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import { Box, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { calculateAverage, objectIsEmpty } from "utils/helper";
import { hrCategoryNameList, sectorNameList } from "utils/constants";

// eslint-disable-next-line react/prop-types
const AreaLayer = ({ innerWidth, innerHeight }) => {
  const heightMultiplier = innerHeight / 5;
  const widthMultiplier = innerWidth / 5;
  return (
    <>
      {/* <path
        d={`M${widthMultiplier * 4} ${heightMultiplier * 4}H${innerWidth}V${
          heightMultiplier * 5
        }H${widthMultiplier * 4}V${heightMultiplier * 4}Z`}
        fill="url(#paint0_linear_854_37)"
      /> */}
      <path
        d={`M${widthMultiplier * 3} ${heightMultiplier * 3}H${innerWidth}V${
          heightMultiplier * 4
        }H${widthMultiplier * 3}V${heightMultiplier * 3}Z`}
        fill="url(#paint0_linear_854_37)"
      />
      <path
        d={`M${widthMultiplier * 2} ${heightMultiplier * 2}H${innerWidth}V${
          heightMultiplier * 3
        }H${widthMultiplier * 2}V${heightMultiplier * 2}Z`}
        fill="url(#paint0_linear_854_37)"
      />
      <path
        d={`M${widthMultiplier * 1} ${heightMultiplier * 1}H${innerWidth}V${
          heightMultiplier * 2
        }H${widthMultiplier * 1}V${heightMultiplier * 1}Z`}
        fill="url(#paint0_linear_854_37)"
      />
      <path
        d={`M${widthMultiplier * 0} ${heightMultiplier * 0}H${innerWidth}V${
          heightMultiplier * 1
        }H${widthMultiplier * 0}V${heightMultiplier * 0}Z`}
        fill="url(#paint0_linear_854_37)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_854_37"
          x1={innerWidth}
          y1={innerHeight * -0.00000012}
          x2={innerWidth * 0.6145}
          y2={innerHeight * 1.601}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF0000" />
          <stop offset="1" stopColor="#FFE601" stopOpacity="0.24" />
        </linearGradient>
      </defs>
    </>
  );
};

const RiskEvalChart = (props) => {
  const { countryAlpha3Code } = props;
  const [data, setData] = useState([]);
  const [xScaleMax, setXScaleMax] = useState(0);
  const titleText = "Sector Risk Assessment";
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();
  const titleInfoText =
    "Risk Evaluation of Category based on the sentiment and custom risk score ";
  const { supplyChainWithIssueCategory } = useSelector(
    (state) => state.compareCountries
  );
  useEffect(() => {
    if (!objectIsEmpty(supplyChainWithIssueCategory) && countryAlpha3Code) {
      const inputData = supplyChainWithIssueCategory[countryAlpha3Code];
      let campaignQuantity = [];
      let tempData = Object.entries(inputData).map(([id, categories]) => ({
        id: sectorNameList[id],
        data: Object.entries(categories).map(([category, categoryData]) => {
          campaignQuantity.push(categoryData.campaign_quantity);
          return {
            x: categoryData.campaign_quantity,
            y: categoryData.risk_score,
            category: hrCategoryNameList[category]["en"],
          };
        }),
      }));

      setXScaleMax(
        Math.max(...campaignQuantity) + calculateAverage(campaignQuantity)
      );
      setData(tempData);
    }
  }, [supplyChainWithIssueCategory, countryAlpha3Code]);
  const scatterPlotConfig = {
    /* ...other configuration options... */
    layers: ["grid", "axes", "nodes", "markers", "mesh", "legends"],
  };
  return (
    <CustomCard
      loading={false}
      titleText={titleText}
      titleInfoText={titleInfoText}
    >
      <ResponsiveScatterPlot
        data={data}
        margin={{ top: 20, right: 140, bottom: 50, left: 50 }}
        xScale={{ type: "linear", min: 0, max: xScaleMax }}
        xFormat=">-.2f"
        yScale={{ type: "linear", min: 0, max: 5 }}
        yFormat=">-.2f"
        colors={{ scheme: "set1" }}
        axisTop={null}
        axisRight={null}
        useMesh={false}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Total Records",
          legendPosition: "middle",
          legendOffset: 40,
        }}
        layers={[
          "grid",
          AreaLayer,
          "axes",
          "nodes",
          "markers",
          "mesh",
          "legends",
          "annotations",
        ]}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Risk Severity Score",
          legendPosition: "middle",
          legendOffset: -40,
        }}
        tooltip={({ node }) => {
          return (
            <Box
              sx={{
                borderRadius: 1,
                color: theme.palette.grey[600],
                background: theme.palette.grey[50],
                maxWidth: "250px",
                border: "1px solid " + theme.palette.grey[200],
              }}
            >
              <Box sx={{ p: 1, background: theme.palette.grey[200] }}>
                <Typography
                  sx={{
                    // color: node.color,
                    fontSize: "1rem",
                  }}
                >
                  {`${node.serieId} Sector`}
                </Typography>
              </Box>
              <Stack
                spacing={1}
                direction="column"
                alignItems="flex-start"
                justifyContent="flex-start"
                sx={{ px: 2, py: 1 }}
              >
                <Typography variant="h3" sx={{ fontFamily: "monospace" }}>
                  {`Risk Score: `} {node.data.y}
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ color: "inherit", fontSize: "0.8rem" }}
                >
                  {`Category: `}
                  <strong>{node.data.category}</strong>
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ color: "inherit", fontSize: "0.8rem" }}
                >
                  {`Record count: `}
                  <strong>{node.data.x}</strong>
                </Typography>

                {/* </Stack> */}
              </Stack>
            </Box>
          );
        }}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 130,
            translateY: 0,
            itemWidth: 100,
            itemHeight: 12,
            itemsSpacing: 5,
            itemDirection: "left-to-right",
            symbolSize: 12,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
      {/* </div> */}
    </CustomCard>
  );
};

export default RiskEvalChart;

RiskEvalChart.propTypes = {
  countryAlpha3Code: PropTypes.string.isRequired,
};
