import { useState } from "react";

import PropTypes from "prop-types";

import { Box, Pagination } from "@mui/material";
import CustomCard from "ui-component/card";
import { NGOReportAccordion } from "./components/NGOReportAccordion";
const NGOIssueListAndDetail = (props) => {
  const { reportList, loading, titleText } = props;
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const recordsPerPage = 15;

  return (
    <CustomCard
      loading={loading}
      noData={reportList.length === 0}
      titleText={titleText}
      hideTitleInfo={true}
      sx={{ border: "none" }}
      bodySx={{ mt: 3, px: 0 }}
    >
      <Box sx={{ width: "100%", height: "100%" }}>
        {reportList.length > recordsPerPage && (
          <Box
            sx={{
              width: "100%",
              height: "40px",
              display: "flex",
              justifyContent: "flex-end",
              px: 2,
            }}
          >
            <Pagination
              page={page}
              onChange={handleChange}
              count={
                reportList.length % recordsPerPage === 0
                  ? Math.floor(reportList.length / recordsPerPage)
                  : Math.floor(reportList.length / recordsPerPage) + 1
              }
              size="medium"
              shape="rounded"
              showFirstButton
              showLastButton
              color="secondary"
              sx={{
                width: "auto",
                height: "100%",
              }}
            />
          </Box>
        )}
        <Box sx={{ height: "calc( 100% - 40px )", overflowY: "scroll", px: 2 }}>
          {reportList
            .slice(recordsPerPage * (page - 1), recordsPerPage * page)
            .map((issue, idx) => {
              return (
                <NGOReportAccordion
                  // key={idx}
                  idx={recordsPerPage * (page - 1) + idx}
                  key={recordsPerPage * (page - 1) + idx}
                  issue={issue}
                />
              );
            })}
        </Box>
      </Box>
    </CustomCard>
  );
};

NGOIssueListAndDetail.propTypes = {
  reportList: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  articleCount: PropTypes.number,
  showArticleCount: PropTypes.bool,
  titleText: PropTypes.string,
};

NGOIssueListAndDetail.defaultProps = {
  loading: true,
  showArticleCount: true,
};

export default NGOIssueListAndDetail;
