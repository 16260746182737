import PropTypes from "prop-types";

// material-ui
import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";

// project imports
import MainCard from "ui-component/cards/MainCard";
import WidgetCardSkeleton from "./Skeleton/WidgetCard";

// ===========================|| DASHBOARD DEFAULT - WIDGET CARD ||=========================== //

const PrimaryCardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: "#fff",
  overflow: "hidden",
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    background: theme.palette.primary[800],
    borderRadius: "50%",
    top: -85,
    right: -95,
    [theme.breakpoints.down("sm")]: {
      top: -105,
      right: -140,
    },
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    background: theme.palette.primary[800],
    borderRadius: "50%",
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down("sm")]: {
      top: -155,
      right: -70,
    },
  },
}));
const SecondaryCardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  color: "#fff",
  overflow: "hidden",
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    background: theme.palette.secondary[800],
    borderRadius: "50%",
    top: -85,
    right: -95,
    [theme.breakpoints.down("sm")]: {
      top: -105,
      right: -140,
    },
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    background: theme.palette.secondary[800],
    borderRadius: "50%",
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down("sm")]: {
      top: -155,
      right: -70,
    },
  },
}));
const WidgetCard = (props) => {
  const { isLoading, cardTheme, children } = props;

  return (
    <>
      {isLoading ? (
        <WidgetCardSkeleton />
      ) : (
        <>
          {cardTheme === "primary" ? (
            <PrimaryCardWrapper border={false} content={false}>
              <Box
                sx={{
                  p: 3,
                  width: "100%",
                  height: 180,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  zIndex: 5,
                }}
              >
                {children}
              </Box>
            </PrimaryCardWrapper>
          ) : (
            <SecondaryCardWrapper border={false} content={false}>
              <Box
                sx={{
                  p: 2.5,
                  width: "100%",
                  height: 180,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  zIndex: 5,
                }}
              >
                {children}
              </Box>
            </SecondaryCardWrapper>
          )}
        </>
      )}
    </>
  );
};

WidgetCard.propTypes = {
  isLoading: PropTypes.bool,
  cardTheme: PropTypes.string,
  children: PropTypes.element,
};

export default WidgetCard;
