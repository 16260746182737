/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import {
  Box,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import config from "config";

import CustomCard from "ui-component/card";
import TableView from "./TableView";
import InsightView from "./InsightView";
import { useSelector } from "react-redux";
// import RiskLevelCellRenderer from "./TableView/NGOIssueListAndDetail/components/RiskLevelCellRenderer";
// import SentimentCellRenderer from "./TableView/NGOIssueListAndDetail/components/SentimentCellRenderer";
import { capitalizeAndRemoveUnderscore, objectIsEmpty } from "utils/helper";
import { hrCategoryNameList, sectorNameList } from "utils/constants";
// assets
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
// import PivotTableChartOutlinedIcon from "@mui/icons-material/PivotTableChartOutlined";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import SentimentCellRenderer from "./TableView/NGOIssueListAndDetail/components/SentimentCellRenderer";
import RiskLevelCellRenderer from "./TableView/NGOIssueListAndDetail/components/RiskLevelCellRenderer";

const CompareCountriesResult = (props) => {
  const theme = useTheme();
  const { loading } = props;
  const [rowData, setRowData] = useState([]);
  const [tableDataLoading, setTableDataLoading] = useState(true);
  const [dataVisualization, setDataVisualization] = useState("tableView");
  const {
    selectedCountries: storeSelectedCountryList,
    sector: searchedSectorList,
    category: searchedCategoryList,
  } = useSelector((state) => state.compareCountries.search);

  const {
    supplyChain,
    countryDetail,
    countryIndicatorData,
    populationIndicatorData,
    humanRights,
  } = useSelector((state) => state.compareCountries);

  const dataVisualizationOption = [
    {
      id: "tableView",
      name: "Compare Table",
      icon: <TableChartOutlinedIcon fontSize="small" />,
    },
    {
      id: "chartView",
      name: "Sector Insight",
      icon: <InsightsOutlinedIcon fontSize="small" />,
    },
  ];

  const handleDataVisualizationChange = (event, newDataViualization) => {
    if (newDataViualization !== null) {
      setDataVisualization(newDataViualization);
    }
  };
  const columns = useMemo(() => {
    const selectedCountries = storeSelectedCountryList.map(
      (item) => item.country
    );
    const countriesColumn = selectedCountries.map((country) => {
      return {
        accessorKey: country.toLowerCase(),
        header: country,
        size: 300,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) => {
          if (
            row?.original?.indicatorKey === "supplyChain" ||
            row?.original?.indicatorKey === "humanRights"
          ) {
            return (
              <SentimentCellRenderer
                series={renderedCellValue?.series}
                total={renderedCellValue?.total}
              />
            );
          } else if (row?.original?.indicatorKey === "countryBasedIndicator") {
            return (
              <RiskLevelCellRenderer
                data={renderedCellValue?.actual_score}
                riskLevel={renderedCellValue?.risk_level}
              />
            );
          }
          return <Box>{renderedCellValue}</Box>;
        },
      };
    });

    return [
      {
        accessorKey: "indicator",
        header: "Indicator",
        size: 200,
        enableColumnFilter: false,
      },
      {
        accessorKey: "subIndicator",
        accessorFn: (row) => {
          return (
            <Tooltip title={row["subIndicator"]}>
              <Typography>{row["subIndicator"]}</Typography>
            </Tooltip>
          );
        },
        header: "Sub Indicator",
        size: 350,
        enableColumnFilter: false,
      },
      ...countriesColumn,
    ];
  }, [storeSelectedCountryList]);

  useEffect(() => {
    if (loading === false) {
      setTableDataLoading(true);
      const selectedCountries = storeSelectedCountryList.map(
        (item) => item.country
      );
      setRowData([]);

      let tempRowData = [];

      searchedSectorList.forEach((key) => {
        let result = {};
        for (const country of selectedCountries) {
          let combinedObject = {};
          if (!objectIsEmpty(supplyChain) && supplyChain?.[country]?.[key]) {
            let sourceObject = supplyChain[country][key];
            let series = [
              {
                name: "Positive",
                data: [sourceObject["positive_sentiment_qnty"]],
              },
              {
                name: "Neutral",
                data: [sourceObject["neutral_sentiment_qnty"]],
              },
              {
                name: "Negative",
                data: [sourceObject["negative_sentiment_qnty"]],
              },
            ];
            combinedObject = {
              series: series,
              total: sourceObject["campaign_quantity"],
              id: "supplyChainRisk",
              action_no: key,
              country_no: sourceObject["country_no"],
            };
          } else {
            combinedObject = null;
          }
          result[country.toLowerCase()] = combinedObject;
        }
        let tempData = {
          indicatorKey: "supplyChain",
          indicator: "Supply Chain",
          subIndicator: sectorNameList[key],
          ...result,
        };
        tempRowData = [...tempRowData, tempData];
      });

      searchedCategoryList.forEach((key) => {
        let result = {};
        for (const country of selectedCountries) {
          let combinedObject = {};
          if (!objectIsEmpty(humanRights) && humanRights?.[country]?.[key]) {
            let sourceObject = humanRights[country][key];
            let series = [
              {
                name: "Positive",
                data: [sourceObject["positive_sentiment_qnty"]],
              },
              {
                name: "Neutral",
                data: [sourceObject["neutral_sentiment_qnty"]],
              },
              {
                name: "Negative",
                data: [sourceObject["negative_sentiment_qnty"]],
              },
            ];
            combinedObject = {
              series: series,
              total: sourceObject["campaign_quantity"],
              id: "humanRightsRisk",
              action_no: key,
              country_no: sourceObject["country_no"],
            };
          } else {
            combinedObject = null;
          }
          result[country.toLowerCase()] = combinedObject;
        }
        let tempData = {
          indicatorKey: "humanRights",
          indicator: "Human Rights",
          subIndicator: hrCategoryNameList[key]["en"],
          ...result,
        };
        tempRowData = [...tempRowData, tempData];
      });

      const populationBasedIndicatorKey = [
        "female_migration",
        "international_migration_UN",
        "national_poverty_line",
        "population_of_concern",
        "severe_multidimensional_poverty",
        "vulnerable_employment",
      ];

      const countryBasedIndicatorKey = {
        "Civil Liberties": [
          "civil_liberties_rating_actual_score",
          "civil_liberties_rating_custom_score",
          "civil_liberties_rating_risk_level",
        ],
        "Corruption Perceptions Index": [
          "corruption_perceptions_index_actual_score",
          "corruption_perceptions_index_custom_score",
          "corruption_perceptions_index_risk_level",
        ],
        "Depth of Credit Information": [
          "depth_of_credit_information_index_actual_score",
          "depth_of_credit_information_index_custom_score",
          "depth_of_credit_information_index_risk_level",
        ],
        "Fragile States Index": [
          "fragile_states_index_actual_score",
          "fragile_states_index_custom_score",
          "fragile_states_index_risk_level",
        ],
        "Global Gender Gap": [
          "global_gender_gap_report_actual_score",
          "global_gender_gap_report_custom_score",
          "global_gender_gap_report_risk_level",
        ],
        "Global Rights Index": [
          "global_rights_index_actual_score",
          "global_rights_index_custom_score",
          "global_rights_index_risk_level",
        ],
        "Inequality Adjusted Human Developement Index": [
          "inequality_adjusted_human_development_index_actual_score",
          "inequality_adjusted_human_development_index_custom_score",
          "inequality_adjusted_human_development_index_risk_level",
        ],
        "Political Stability": [
          "political_stability_actual_score",
          "political_stability_custom_score",
          "political_stability_risk_level",
        ],
        "Rule of Law Index": [
          "rule_of_law_index_actual_score",
          "rule_of_law_index_custom_score",
          "rule_of_law_index_risk_level",
        ],
        "Trafficiking In Persons Report": [
          "trafficking_in_persons_report_actual_score",
          "trafficking_in_persons_report_custom_score",
          "trafficking_in_persons_report_risk_level",
        ],
      };

      if (!objectIsEmpty(populationIndicatorData)) {
        populationBasedIndicatorKey.forEach((key) => {
          let result = {};

          for (const country of selectedCountries) {
            result[country.toLowerCase()] =
              populationIndicatorData[country][key];
          }
          let tempData = {
            indicatorKey: "Population Based Indicator",
            indicator: "Population Based Indicator",
            subIndicator: capitalizeAndRemoveUnderscore(key),
            ...result,
          };
          tempRowData = [...tempRowData, tempData];
        });
      }

      if (!objectIsEmpty(countryIndicatorData)) {
        Object.keys(countryBasedIndicatorKey).forEach((key) => {
          let result = {};
          for (const country of selectedCountries) {
            let combinedObject = {};
            countryBasedIndicatorKey[key].forEach((subKey) => {
              combinedObject[
                capitalizeAndRemoveUnderscore(subKey)
                  .split(" ")
                  .splice(-2)
                  .join("_")
              ] = countryIndicatorData[country][subKey];
            });
            result[country.toLowerCase()] = combinedObject;
          }

          let tempData = {
            indicatorKey: "countryBasedIndicator",
            // indicator: idx === 0 ? "Country Based Indicator" : "",
            indicator: "Country Based Indicator",
            subIndicator: key,
            ...result,
          };
          tempRowData = [...tempRowData, tempData];
        });
      }
      // adding id for all the records
      let indexedTempRowData = tempRowData.map((item, idx) => {
        return { id: idx + 1, ...item };
      });
      setRowData([...indexedTempRowData]);
      setTableDataLoading(false);
    }
  }, [
    storeSelectedCountryList,
    supplyChain,
    countryDetail,
    countryIndicatorData,
    populationIndicatorData,
    humanRights,
    loading,
  ]);

  return (
    <CustomCard
      loading={loading}
      hideTitle={true}
      bodySx={{ p: 0, m: 0 }}
      sx={{
        height: "100%",
        mt: 2,
        p: 0,
        // overflowY: "scroll",
      }}
    >
      <>
        <TabContext value={dataVisualization}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleDataVisualizationChange}
              aria-label="view-change"
            >
              {dataVisualizationOption.map((item) => {
                return <Tab key={item.id} label={item.name} value={item.id} />;
              })}
            </TabList>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "calc( 100%  - 49px)",
              // dataVisualization !== "tableView"
              //   ? "calc( 100%  - 50px)"
              //   : "auto",
            }}
          >
            <TabPanel
              value={"tableView"}
              sx={{ p: 0, width: "100%", height: "100%" }}
            >
              <TableView
                columns={columns}
                rowData={rowData}
                tableDataLoading={tableDataLoading}
              />
            </TabPanel>
            <TabPanel
              value={"chartView"}
              sx={{ p: 0, width: "100%", height: "100%" }}
            >
              <InsightView />
            </TabPanel>
          </Box>
        </TabContext>
      </>
    </CustomCard>
  );
};

export default CompareCountriesResult;

CompareCountriesResult.propTypes = {
  loading: PropTypes.bool,
  // searchAccordionState: PropTypes.bool,
};
