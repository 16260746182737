/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";

import List from "@mui/material/List";
// import { ListSubheader } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
// redux
// eslint-disable-next-line no-unused-vars
import { useDispatch, useSelector } from "react-redux";
import {
  SET_SELECTED_SIDE_MENU,
  SET_SIDE_DRAWER_STATE,
} from "redux/customization/actions";

// assets
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import CompareArrowsOutlinedIcon from "@mui/icons-material/CompareArrowsOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

// constant
const icons = {
  ApartmentOutlinedIcon,
  PictureAsPdfOutlinedIcon,
  PublicOutlinedIcon,
  FlagOutlinedIcon,
  CompareArrowsOutlinedIcon,
  AssessmentOutlinedIcon,
};

// ==============================|| SIDEBAR MENU LIST ||============================== //

const NavItem = ({ id, icon, text, url, textVariant = "h5" }) => {
  const Icon = icon;
  const theme = useTheme();
  const dispatch = useDispatch();
  const selectedDrawerMenuItem = useSelector(
    (state) => state.customization.selectedDrawerMenuItem
  );
  const { selectedCountry } = useSelector((state) => state.countryRisk);

  const itemIcon = <Icon stroke={2} size="1.3rem" />;
  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname.toString().includes(url);
    if (currentIndex) {
      dispatch({ type: SET_SELECTED_SIDE_MENU, id });
    }
    // eslint-disable-next-line
  }, [document.location.pathname]);
  return (
    <ListItemButton
      component={Link}
      to={id === "countryRisk" && selectedCountry ? url + selectedCountry : url}
      selected={selectedDrawerMenuItem === id}
      onClick={() => {
        dispatch({ type: SET_SIDE_DRAWER_STATE, sideDrawerOpened: false });
        dispatch({ type: SET_SELECTED_SIDE_MENU, id: id });
      }}
      sx={{
        mb: 1,
        borderRadius: "4px",
        "&.Mui-selected": {
          color: theme.palette.secondary.dark,
          backgroundColor: theme.palette.grey[700],
          ":hover": {
            backgroundColor: theme.palette.grey[700],
          },
        },
        ":hover": {
          backgroundColor: theme.palette.grey[700],
        },
        // color:
        //   selectedDrawerMenuItem === id
        //     ? theme.palette.grey[900]
        //     : theme.palette.grey[50],
      }}
    >
      <ListItemIcon sx={{ color: "inherit" }}>{itemIcon}</ListItemIcon>
      <Typography
        variant={textVariant}
        sx={{ fontWeight: 700 }}
        color="inherit"
      >
        {text}
      </Typography>
    </ListItemButton>
  );
};

const MenuList = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <List
      sx={{ width: "100%" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton onClick={handleClick}>
        <ListItemIcon sx={{ color: theme.palette.grey[50] }}>
          <FlagOutlinedIcon stroke={2} size="1.3rem" />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="h4" sx={{ fontWeight: 700 }} color="inherit">
            {"Country"}
          </Typography>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ pl: 4 }}>
          <NavItem
            icon={icons.PublicOutlinedIcon}
            text={"Heat Map"}
            url={"/"}
            id={"heatMap"}
          />
          <NavItem
            icon={icons.AssessmentOutlinedIcon}
            text={"Risk Analysis"}
            url={"/country-risk/"}
            id={"countryRisk"}
          />
          {/* <NavItem
            icon={icons.PictureAsPdfOutlinedIcon}
            text={"Generate Report"}
            url={"/generate-pdf"}
            id={"generatePDF"}
            // textVariant="h4"
          /> */}
        </List>
      </Collapse>

      {/* compare countries */}
      <NavItem
        id={"compareCountries"}
        icon={icons.CompareArrowsOutlinedIcon}
        text={"Compare Country "}
        url={"/compare-countries"}
        textVariant={"h4"}
        sx={{ fontWeight: 700 }}
      />
      {/* company risk */}
      <NavItem
        icon={icons.ApartmentOutlinedIcon}
        text={"Entity Risk"}
        textVariant={"h4"}
        url={"/company-risk/"}
        id={"companyRisk"}
      />
    </List>
  );
};

export default MenuList;
