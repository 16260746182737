import { combineReducers } from "redux";
import CompanyRiskReducer from "./companyRisk/reducer";
import CountriesComparisionReducer from "./compareCountries/reducer";
import CountryRiskReducer from "./countryRisk/reducer";

// reducer import
import customizationReducer from "./customization/reducer";
import { ServiceAndRequestReducer } from "./serviceAndRequest/reducer";
import UserReducer from "./authentication/reducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  serviceAndRequest: ServiceAndRequestReducer,
  countryRisk: CountryRiskReducer,
  compareCountries: CountriesComparisionReducer,
  companyRisk: CompanyRiskReducer,
  user: UserReducer,
});

export default reducer;
