import React from "react";
import useTheme from "@mui/material/styles/useTheme";
import { Box, Typography } from "@mui/material";
import FolderOffOutlinedIcon from "@mui/icons-material/FolderOffOutlined";

const NoData = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <FolderOffOutlinedIcon fontSize="large" />
      <Typography
        variant="h4"
        sx={{
          fontWeight: "300",
          color: theme.palette.text.primary,
          fontFamily: "monospace",
        }}
      >
        {"No Data Found:("}
      </Typography>
    </Box>
  );
};

export default NoData;
