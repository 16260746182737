export const hrCategoryNameList = {
  "exp.ch.lab": {
    en: "Exploitative use of child labor",
    ja: "児童労働の搾取的利用",
    score: 4.4,
  },
  "coer.inv.lab": {
    en: "Coercive and involuntary labor",
    ja: "強制労働と非自発的労働",
    score: 4.0,
  },
  "abus.res.ext": {
    en: "Human rights abuses in resource extraction",
    ja: "資源採取における人権侵害",
    score: 4.1,
  },
  "exp.wrk.hrs": {
    en: "Exploitative working hours",
    ja: "搾取的労働時間",
    score: 3.0,
  },
  "prot.mgrt.wrks": {
    en: "Protection of rights for migrant workers",
    ja: "移民労働者の権利保護",
    score: 3.4,
  },
  "exp.lab.prct": {
    en: "Exploitative labor practices in terms of wages",
    ja: "賃金面での搾取的労働慣行",
    score: 2.6,
  },
  "wrkplc.hlth.sft": {
    en: "Ensuring workplace health and safety",
    ja: "職場の安全衛生の確保",
    score: 3.2,
  },
  "prev.hrsmt.abus": {
    en: "Prevention of harassment and abuse",
    ja: "ハラスメントと虐待の防止",
    score: 2.6,
  },
  "cmbt.disc": {
    en: "Combating discrimination",
    ja: "差別との闘い",
    score: 2.0,
  },
  "add.gndr.mnrt": {
    en: "Addressing gender and minority",
    ja: "ジェンダーとマイノリティへの対応",
    score: 2.5,
  },
  "hlth.edu": {
    en: "Access to healthcare and education",
    ja: "ヘルスケアと教育へのアクセス",
    score: 2.4,
  },
  "intel.prop": {
    en: "Upholding intellectual property rights",
    ja: "知的財産権の尊重",
    score: 2.0,
  },
  "cncr.tech.ai": {
    en: "Ethical concerns in technology and AI",
    ja: "テクノロジーとAIにおける倫理的懸念",
    score: 2.5,
  },
  "fre.exp": {
    en: "Protection of freedom of expression",
    ja: "表現の自由の保護",
    score: 3.0,
  },
  "con.sft.inf": {
    en: "Ensuring consumer safety and information rights",
    ja: "消費者の安全と情報の権利の確保",
    score: 3.0,
  },
  "sfgrd.priv": {
    en: "Safeguarding the right to privacy",
    ja: "プライバシー権の保護",
    score: 2.6,
  },
  "brby.corr.tax": {
    en: "Combating bribery, corruption, and tax evasion",
    ja: "贈収賄、汚職、脱税との闘い",
    score: 2.5,
  },
  "mil.cnft.reg": {
    en: "Human rights concerns in military, conflict, and specific regional contexts",
    ja: "軍事、紛争、特定の地域的文脈における人権への懸念",
    score: 2.7,
  },
  "acs.rmd.viol": {
    en: "Ensuring access to remedies for human rights violations",
    ja: "人権侵害に対する救済へのアクセスの確保",
    score: 2.2,
  },
  "indi.loc.ppl": {
    en: "Protection of indigenous and local people's rights",
    ja: "先住民および地域住民の権利の保護",
    score: 2.7,
  },
  "aadr.abus.supc": {
    en: "Addressing human rights abuses in the supply chain",
    ja: "サプライチェーンにおける人権侵害への対応",
    score: 2.7,
  },
  "addr.env.cli": {
    en: "Addressing environmental and climate-related human rights concerns",
    ja: "環境および気候に関連する人権問題への対応",
    score: 2.6,
  },
  "prot.anml.wlfr": {
    en: "Protection of animal welfare",
    ja: "動物福祉の保護",
    score: 1.5,
  },
};

export const sectorNameList = {
  "CG.1": "Apparel & Textiles",
  "CG.2": "Consumer Discretionary Products",
  "CG.3": "Consumer Goods Retail",
  "EM.1": "Coal",
  "EM.2": "Construction Materials",
  "EM.3": "Metals & Mining",
  "EM.4": "Oil & Gas",
  "FB.1": "Food",
  "FB.2": "Beverages",
  "FB.3": "Food & Beverages Retail",
  "FB.4": "Restaurants",
  "FB.5": "Tobacco",
  "FN.1": "Capital Markets",
  "FN.2": "Corporate & Retail Banking",
  "FN.3": "Insurance",
  "HC.1": "Biotechnology & Pharmaceuticals",
  "HC.2": "Health Care Retail",
  "HC.3": "Health Care Providers",
  "HC.4": "Medical Technology",
  "IF.1": "Utilities",
  "IF.2": "Infrastructure",
  "IF.3": "Real Estate",
  "IF.4": "Waste Management",
  "RR.1": "Alternative Energy",
  "RR.2": "Forestry & Paper ",
  "RT.1": "Industrials",
  "RT.2": "Chemicals",
  "SV.1": "Media",
  "SV.2": "Hospitality & Recreation",
  "SV.3": "Consumer Services",
  "TC.1": "Technology",
  "TC.2": "Internet Media & Services",
  "TC.3": "Semiconductors",
  "TC.4": "Telecommunications",
  "TR.1": "Air Transportation",
  "TR.2": "Automobiles",
  "TR.3": "Marine Transportation",
  "TR.4": "Land Transportation",
};
