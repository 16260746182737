/* eslint-disable no-unused-vars */
import { Box } from "@mui/material";

import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getBrowserStorageAccessToken } from "utils/authentication";
import {
  BASE_USER_ACTION_NAME,
  GET_USER_INFORMATION,
} from "redux/authentication/action";
import UpdatePassword from "./updatePassword";
import UserInformation from "./userInfo";
import { namedRequestsInProgress } from "redux/serviceAndRequest/selector";
import Loader from "ui-component/Loader";

const UserAccount = () => {
  const dispatch = useDispatch();
  const userInformationState = useSelector((state) => state.user);
  const fetchingUserInformation = useSelector((state) =>
    namedRequestsInProgress(state, {
      moduleName: BASE_USER_ACTION_NAME,
      requestName: GET_USER_INFORMATION,
    })
  );
  useEffect(() => {
    dispatch({
      type: GET_USER_INFORMATION,
      token: getBrowserStorageAccessToken(),
    });
  }, []);

  return (
    <Box sx={{ px: 5, width: "100%", height: "100%" }}>
      <Box sx={{ height: "100%", width: "100%", minHeight: "40vh" }}>
        {fetchingUserInformation === false ? (
          <>
            <UserInformation userInfo={userInformationState} />
            <UpdatePassword />
          </>
        ) : (
          <Loader />
        )}
      </Box>
    </Box>
  );
};

export default UserAccount;
