import { axiosJWT } from "common/axiosPrivate";

// const endUrl = "/";
export const getCompanyReport = async (payload) => {
  try {
    let response = await axiosJWT.post(
      `esg/generate-company-report/`,
      payload,
      {
        responseType: "arraybuffer",
      }
    );
    const pdfBlob = new Blob([response.data], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(pdfBlob);
    link.download = "webint-report.pdf";
    link.click();
    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};
export const getCSLSanction = async (companyName) => {
  try {
    let response = await axiosJWT.get(
      `esg/csl-sanction?company=${companyName}`
    );
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};

export const getTwitterData = async (companyName) => {
  try {
    let response = await axiosJWT(`esg/twitter-data/?company=${companyName}`);
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};
// export const getTwitterData = async (
//   companyName,
//   publishedStartDate,
//   publishedEndDate,
//   categories
// ) => {
//   try {
//     let response = await axiosJWT(
//       `esg/company-tweets/?company=${companyName}&published_start_date=${publishedStartDate}&published_end_date=${publishedEndDate}&categories=${categories}`
//     );
//     return response.data;
//   } catch (e) {
//     throw "API threw error";
//   }
// };

export const getNGOActionGrequencyAndMovingAverage = async (
  companyName,
  publishedStartDate,
  publishedEndDate,
  categories,
  searchType
) => {
  try {
    let response = await axiosJWT(
      `esg/activist-actions-over-time/?company=${companyName}&search_type=${searchType}&category=${categories}&start_date=${publishedStartDate}&end_date=${publishedEndDate}`
    );
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};

export const getNGOReportData = async (
  companyName,
  publishedStartDate,
  publishedEndDate,
  categories,
  searchType
) => {
  try {
    let response = await axiosJWT(
      `esg/company-ngo-reports/?company=${companyName}&search_type=${searchType}&category=${categories}&start_date=${publishedStartDate}&end_date=${publishedEndDate}`
    );
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};
export const getNewsArticles = async (
  companyName,
  publishedStartDate,
  publishedEndDate,
  titleSentiment,
  categories,
  searchType
) => {
  try {
    let response = await axiosJWT(
      `esg/company-news-articles/?published_start_date=${publishedStartDate}&published_end_date=${publishedEndDate}&company=${companyName}&search_type=${searchType}&title_sentiment=${titleSentiment}&categories=${categories}`
    );
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};

export const getTopNewsSource = async (
  companyName,
  publishedStartDate,
  publishedEndDate,
  titleSentiment,
  categories
) => {
  try {
    let response = await axiosJWT(
      `esg/company-news-top-source/?published_start_date=${publishedStartDate}&published_end_date=${publishedEndDate}&company=${companyName}&title_sentiment=${titleSentiment}&categories=${categories}`
    );
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};

export const getCommonlyUsedKeyword = async (
  companyName,
  publishedStartDate,
  publishedEndDate,
  titleSentiment,
  categories
) => {
  try {
    let response = await axiosJWT(
      `esg/company-news-common-keywords/?published_start_date=${publishedStartDate}&published_end_date=${publishedEndDate}&company=${companyName}&title_sentiment=${titleSentiment}&categories=${categories}`
    );
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};
export const getNewsVolume = async (
  companyName,
  publishedStartDate,
  publishedEndDate,
  titleSentiment,
  categories
) => {
  try {
    let response = await axiosJWT(
      `esg/company-news-volume/?published_start_date=${publishedStartDate}&published_end_date=${publishedEndDate}&company=${companyName}&title_sentiment=${titleSentiment}&categories=${categories}`
    );
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};
export const getNewsEntities = async (
  companyName,
  publishedStartDate,
  publishedEndDate,
  titleSentiment,
  categories
) => {
  try {
    let response = await axiosJWT(
      `esg/company-news-common-entities/?published_start_date=${publishedStartDate}&published_end_date=${publishedEndDate}&company=${companyName}&title_sentiment=${titleSentiment}&categories=${categories}`
    );
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};
export const getAutoCompleteData = async (companyName) => {
  try {
    let response = await axiosJWT(
      `esg/company-autocomplete/?company=${companyName}`
    );
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};

export const getCompanyDetail = async (companyName) => {
  try {
    let response = await axiosJWT(`esg/company-detail/?company=${companyName}`);
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};
export const getCompanyNews = async (
  companyName,
  categories,
  publishedStartDate,
  publishedEndDate,
  japaneseSearch,
  sectors,
  country,
  searchType
) => {
  try {
    let response = await axiosJWT(
      `esg/company-news/?company=${companyName}&search_type=${searchType}&categories=${categories}&start_date=${publishedStartDate}&end_date=${publishedEndDate}&japanese_search=${japaneseSearch}&sectors=${sectors}&country=${country}`
    );
    return response.data;
  } catch (e) {
    throw "API threw error";
  }
};
