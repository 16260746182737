import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// mui
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// assets
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
// import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
// import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { useDispatch } from "react-redux";
import { SET_COUNTRY_ISO_A3 } from "redux/countryRisk/action";
import { Tooltip } from "@mui/material";

const ChangeCountry = (props) => {
  const { countryList, countryAlpha3Code } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let [country, setCountry] = useState(null);
  let [inputCountry, setInputCountry] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "country-search-popover" : undefined;

  const setCountryAndInputCountryFromAlpha3 = () => {
    if (countryList && countryList.length !== 0) {
      let filteredData = countryList.filter(
        (item) => item.alpha3_code === countryAlpha3Code
      )[0];
      setCountry(filteredData);
      setInputCountry(filteredData.country);
    }
  };
  const handleClick = (event) => {
    // if the country is removed and the popover is closed without searching
    // then re calculate the selected country from the alpha3 code
    if (country === null) {
      setCountryAndInputCountryFromAlpha3();
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setCountryAndInputCountryFromAlpha3();
  }, [countryList]);

  return (
    <>
      <Tooltip title="Search a country">
        <Fab
          color="secondary"
          aria-label="edit"
          onClick={handleClick}
          aria-describedby={id}
          size={"small"}
          sx={{
            position: "fixed",
            top: "145px",
            right: "20px",
            float: "right",
          }}
        >
          <TravelExploreIcon />
        </Fab>
      </Tooltip>
      {/* {showPDFView && (
        <Tooltip title="PDF Preview">
          <Fab
            color="secondary"
            aria-label="edit"
            onClick={() => navigate("/generate-pdf")}
            aria-describedby={id}
            size={"small"}
            sx={{
              position: "fixed",
              top: "200px",
              right: "20px",
              float: "right",
            }}
          >
            <PictureAsPdfOutlinedIcon />
          </Fab>
        </Tooltip>
      )} */}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: { height: "170px", width: "300px" },
        }}
      >
        <Stack direction="column" spacing={2} sx={{ px: 1.5, py: 2 }}>
          <Typography variant="h4">{"Change Country"}</Typography>
          <Autocomplete
            size="small"
            options={countryList}
            fullWidth
            value={country}
            onChange={(event, newValue) => {
              setCountry(newValue);
            }}
            inputValue={inputCountry}
            onInputChange={(event, newInputValue) => {
              setInputCountry(newInputValue);
            }}
            getOptionLabel={(option) => option?.country}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              sx={{ width: "50%" }}
              startIcon={<SearchOutlinedIcon />}
              onClick={() => {
                if (country?.alpha3_code)
                  dispatch({
                    type: SET_COUNTRY_ISO_A3,
                    payload: country.alpha3_code,
                  });
                navigate("../country-risk/" + country.alpha3_code);
                handleClose();
              }}
            >
              Search
            </Button>
          </Box>
        </Stack>
      </Popover>
    </>
  );
};

export default ChangeCountry;

ChangeCountry.propTypes = {
  countryAlpha3Code: PropTypes.string,
  countryList: PropTypes.array,
  // showPDFView: PropTypes.bool,
};
