import * as actionTypes from "./action";

export const initialState = {
  email: "",
  emailVerified: false,
  firstName: "",
  lastName: "",
  companyName: "",
  phoneNumber: "",
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_INFORMATION:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default UserReducer;
