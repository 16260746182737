/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { feature } from "topojson";
import { useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

import {
  BASE_COUNTRY_ACTION_NAME,
  GET_WORLD_MAP_JSON_DATA,
  SET_COUNTRY_STATISTICS,
} from "redux/countryRisk/action";
import { namedRequestsInProgress } from "redux/serviceAndRequest/selector";

import { objectIsEmpty } from "utils/helper";

//assets

import { SectorWithHRViolationChart, RiskSentimentChart } from "../components";
import {
  CountryBasedIndicators,
  PopulationBasedIndicators,
  CountryStatistics,
} from "./components";
import {
  CorruptionChart,
  FreedomChart,
  MostTargetedCompaniesByNGOChart,
  SDGBubbleChartNivo,
} from "../components";
import SingleCountryMap from "./components/SingleCountryMap";

const OverviewTab = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { ngoIssue } = useSelector(
    (state) => state.countryRisk.countryStatistics
  );
  let loading = useSelector((state) =>
    namedRequestsInProgress(state, {
      moduleName: BASE_COUNTRY_ACTION_NAME,
      requestName: SET_COUNTRY_STATISTICS,
    })
  );
  const { countryCode } = useParams();
  const {
    name: countryName,
    child_employment: childEmployment,
    indicatorData,
    detail,
  } = useSelector((state) => state.countryRisk.countryStatistics);

  const { worldMapGeoJson } = useSelector((state) => state.countryRisk);

  useEffect(() => {
    if (objectIsEmpty(worldMapGeoJson)) {
      dispatch({ type: GET_WORLD_MAP_JSON_DATA });
    }
  }, [dispatch, worldMapGeoJson]);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        minWidth: "1000px",
        backgroundColor: theme.palette.grey[100],
        py: 2,
      }}
    >
      <Typography
        variant="h2"
        sx={{ color: theme.palette.primary.dark, pl: 2 }}
      >
        {countryName?.toUpperCase()}
      </Typography>
      <Grid container sx={{ p: 4, height: "100%" }} spacing={1}>
        <Grid item xs={12} md={12} xl={8}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={5}
              lg={5}
              xl={6}
              sx={{
                height: "450px",
              }}
            >
              {!objectIsEmpty(worldMapGeoJson) && (
                <SingleCountryMap
                  geoJson={feature(
                    worldMapGeoJson,
                    worldMapGeoJson?.objects?.countries
                  )}
                  flagLink={detail?.flags?.svg}
                  countryAlpha3Code={countryCode}
                ></SingleCountryMap>
              )}
            </Grid>
            <Grid item xs={7} lg={7} xl={6} sx={{ height: "450px" }}>
              <RiskSentimentChart loading={loading} />
            </Grid>
            <Grid item xs={12} sx={{ height: "500px" }}>
              <CorruptionChart loading={loading} />
            </Grid>
            <Grid item xs={6} xl={6} sx={{ height: "475px" }}>
              <MostTargetedCompaniesByNGOChart loading={loading} />
            </Grid>
            <Grid item xs={6} xl={6} sx={{ height: "475px" }}>
              <SectorWithHRViolationChart loading={loading} />
            </Grid>
            <Grid item xs={6} xl={6} sx={{ height: "500px" }}>
              <FreedomChart loading={loading} />
            </Grid>
            {/* <Grid item xs={5.5} xl={5.5} sx={{ height: "550px" }}>
              <CountryDetailSDGGoal loading={loading} />
            </Grid>
            <Grid item xs={6.5} xl={6.5} sx={{ height: "550px" }}>
              <CountryDetailSDGTarget loading={loading} />
            </Grid> */}
            <Grid item xs={6} sx={{ height: "500px", width: "100%" }}>
              <SDGBubbleChartNivo loading={loading} />
            </Grid>
            {/* <Grid item xs={7} sx={{ height: "500px" }}>
              <CountryDetailNGOIssue loading={loading} ngoIssue={ngoIssue} />
            </Grid>
            <Grid item xs={5} sx={{ height: "500px" }}>
              <CountryDetailNGOActionCount loading={loading} />
            </Grid> */}
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} xl={4}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={6}
              md={6}
              xl={12}
              sx={{ height: "auto", minHeight: "500px" }}
            >
              <CountryStatistics indicatorData={indicatorData} />
            </Grid>
            <Grid item xs={6} md={6} xl={12} sx={{ height: "700px" }}>
              <CountryBasedIndicators indicatorData={indicatorData} />
            </Grid>
            <Grid item xs={6} md={6} xl={12} sx={{ height: "465px" }}>
              <PopulationBasedIndicators indicatorData={indicatorData} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OverviewTab;
