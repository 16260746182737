import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

// mui
import useTheme from "@mui/material/styles/useTheme";

// ui-component
import CustomCard from "ui-component/card";

const CorruptionChart = (props) => {
  const { loading } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    name: countryName,
    corruption,
    corruptionAssociationAverage,
  } = useSelector((state) => state.countryRisk.countryStatistics);
  const [series, setSeries] = useState([]);
  const [option, setOption] = useState({});
  let chartTitle = t("corruptionChartTitleI18n");
  let xaxisName = t("yearI18n");
  let yaxisLeftName = t("corruptionChartYAxisLeftNameI18n");
  let yaxisRightName = t("corruptionChartYAxisRightNameI18n");

  useEffect(() => {
    if (corruption && corruption?.length > 0) {
      setSeries([
        {
          name: countryName,
          data: corruption.map((data) => data.score),
          type: "column",
        },

        {
          name: countryName + " - Rank",
          data: corruption.map((data) => data.rank),
          type: "line",
        },
        {
          name: "BRICS",
          data: corruptionAssociationAverage.brics.map((data) => data.score),
          type: "column",
        },
        {
          name: "European Union (EU)",
          data: corruptionAssociationAverage.eu.map((data) => data.score),
          type: "column",
        },
        {
          name: "G20",
          data: corruptionAssociationAverage.g20.map((data) => data.score),
          type: "column",
        },
        {
          name: "ASEAN",
          data: corruptionAssociationAverage.asean.map((data) => data.score),
          type: "column",
        },
      ]);
      setOption({
        chart: {
          stacked: false,
          toolbar: {
            show: true,
          },
        },
        grid: {
          show: true,
          padding: {
            left: 100,
            right: 100,
          },
        },
        colors: [
          // "#ffa600",
          theme.palette.chart[900],
          theme.palette.secondary[800], // line cahrt  color
          theme.palette.chart[800],
          theme.palette.chart[700],
          theme.palette.chart[600],
          theme.palette.chart[500],
          // "#ff6361",
          // "#bc5090",
          // "#58508d",
          // "#003f5c",
        ],
        markers: {
          size: 20,
          shape: "circle",
          fillOpacity: 1,
          colors: [theme.palette.secondary.main],
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
            columnWidth: "50%",
          },
        },
        stroke: {
          width: 2,
          curve: "straight",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
          style: {
            colors: [theme.palette.grey[900]],
          },
          background: {
            enabled: false,
          },
        },
        legend: {
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        yaxis: [
          {
            title: {
              text: yaxisLeftName,
            },
            min: 0,
            max: 100,
            show: true,
          },
          {
            logBase: 10,
            opposite: true,
            title: {
              text: yaxisRightName,
            },
          },
          {
            title: {
              text: yaxisLeftName,
            },
            min: 0,
            max: 100,
            show: false,
          },
          {
            title: {
              text: yaxisLeftName,
            },
            min: 0,
            max: 100,
            show: false,
          },
          {
            title: {
              text: yaxisLeftName,
            },
            min: 0,
            max: 100,
            show: false,
          },
          {
            title: {
              text: yaxisLeftName,
            },
            min: 0,
            max: 100,
            show: false,
          },
        ],
        xaxis: {
          title: {
            text: xaxisName,
          },
          categories: corruption.map((data) => data.year),
          labels: {
            show: true,
          },
        },
      });
    }
  }, [corruption, xaxisName, yaxisLeftName, yaxisRightName]);
  return (
    <CustomCard
      titleText={chartTitle}
      titleInfoText={t("corruptionChartInfoI18n")}
      loading={loading}
      noData={corruption?.length === 0}
    >
      {option && series.length !== 0 && (
        <Chart
          options={option}
          series={series}
          type="line"
          height="100%"
          width="100%"
        />
      )}
    </CustomCard>
    // <Box
    //   sx={{
    //     width: "100%",
    //     height: "100%",
    //     border: "1px solid" + theme.palette.grey[300],
    //     borderRadius: "4px",
    //     backgroundColor: theme.palette.background.paper,
    //     p: 2,
    //   }}
    // >
    //   <Stack direction="row" spacing={1} sx={{ width: "100%", height: "40px" }}>
    //     <Typography
    //       variant="h3"
    //       sx={{
    //         color: theme.palette.text.dark,
    //       }}
    //     >
    //       {chartTitle}
    //     </Typography>

    //     <HtmlTooltip
    //       title={
    //         <Typography variant="h4">{t("corruptionChartInfoI18n")}</Typography>
    //       }
    //     >
    //       <InfoIcon
    //         fontSize="small"
    //         sx={{
    //           cursor: "pointer",
    //           height: "15px",
    //           color: theme.palette.primary.dark,
    //         }}
    //       />
    //     </HtmlTooltip>
    //   </Stack>

    //   <Grid
    //     item
    //     sx={{
    //       height: "calc( 100% - 40px)",
    //       width: "100%",
    //     }}
    //   >
    //     {option && series.length !== 0 && (
    //       <Chart
    //         options={option}
    //         series={series}
    //         type="line"
    //         height="100%"
    //         width="100%"
    //       />
    //     )}
    //   </Grid>
    // </Box>
  );
};

export default CorruptionChart;
CorruptionChart.propTypes = {
  loading: PropTypes.bool,
};
