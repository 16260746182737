/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

// mui
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";

// d3
import { select, geoPath, geoAlbersUsa, geoMercator, zoom as d3Zoom } from "d3";
import { geoCylindricalStereographic } from "d3-geo-projection";

const SingleCountryMap = (props) => {
  const { geoJson, countryAlpha3Code, flagLink } = props;

  const theme = useTheme();
  const wrapperRef = useRef();

  const drawWorldMap = (height, width) => {
    // deleting old instance if any
    const data = JSON.parse(JSON.stringify(geoJson));
    if (document.getElementById("miniMap")) {
      document.getElementById("miniMap").remove();
    }

    const svg = select(wrapperRef.current).append("svg");
    svg
      .attr("viewBox", [0, 0, width, height])
      .attr("preserveAspectRatio", "none")
      .attr("id", "miniMap")
      .style("cursor", "pointer")
      .style("top", 0)
      .style("left", 0)
      .style("display", "inline-block")
      .style("position", "absolute");

    data.features = data.features.filter(function (d) {
      return d.properties.iso_a3_eh === countryAlpha3Code;
    });
    // const g = svg.append("g");
    let projection = "";
    switch (countryAlpha3Code) {
      case "USA":
        projection = geoAlbersUsa()
          .fitSize([width, height], data)
          .scale(width / 1.5)
          .precision(100);
        break;
      case "RUS":
        projection = geoMercator()
          .fitSize([width, height], data)
          .rotate([-11, 0])
          .precision(100);
        break;
      default:
        projection = geoCylindricalStereographic().fitSize(
          [width, height],
          data
        );
    }

    // takes data,
    // transforms that into the d attribute of a path element
    const pathGenerator = geoPath().projection(projection);

    svg
      .selectAll("path")
      .data(data.features)
      .join("path")
      .attr("class", "country")
      .attr("fill", theme.palette.grey[400])
      .attr("stroke-width", 0.4)
      .style("opacity", 1)
      .style("stroke", theme.palette.grey[600])
      .attr("d", (feature) => pathGenerator(feature));
    // svg.call(zoom);
  };

  useEffect(() => {
    if (wrapperRef?.current.offsetHeight && wrapperRef?.current.offsetWidth) {
      drawWorldMap(
        wrapperRef?.current.offsetHeight,
        wrapperRef?.current.offsetWidth
      );
    }
  }, [
    wrapperRef?.current,
    wrapperRef?.current?.offsetHeight,
    wrapperRef?.current?.offsetWidth,
    countryAlpha3Code,
    geoJson,
  ]);
  return (
    // parent div
    <Box
      component="div"
      sx={{
        height: "100%",
        width: "100%",
        position: "relative",
        overflow: "hidden",
        display: "block",
        bgcolor: theme.palette.chart["800"],
        borderRadius: "4px",
      }}
    >
      <Box
        ref={wrapperRef}
        component="div"
        sx={{
          position: "relative",
          display: "block",
          width: "100%",
          overflow: "hidden",
          top: "50%",
          left: "50%",
          // paddingBottom: matchdownXLandupLG ? "80%" : "50%",
          paddingBottom: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {/* svg will come here */}
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "10px",
          right: "10px",
          width: "auto",
          // height: matchdownXLandupLG ? "70px" : "100px",
          height: "70px",
        }}
      >
        <img
          crossOrigin="anonymous"
          src={flagLink ? flagLink : ""}
          width="auto"
          height="100%"
        />
      </Box>
    </Box>
  );
};

export default SingleCountryMap;
SingleCountryMap.propTypes = {
  geoJson: PropTypes.object,
  countryAlpha3Code: PropTypes.string,
  flagLink: PropTypes.string,
};
